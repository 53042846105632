import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {SET_AUTHENTICATED} from "./context/actions";
import InfoMessage from "./InfoMessage";
import * as AuthService from "./services/auth-service";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://psdruk.com.ua/">
        psdruk.com.ua
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [form, setForm] = useState({username: "", password: ""});
  const [showNotYetVerified, setShowNotYetVerified] = useState(false);
  const [showWrongCredentials, setShowWrongCredentials] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const setUsername = (e) => setForm({...form, username: e.target.value});
  const setPassword = (e) => setForm({...form, password: e.target.value});

  const handleClose = () => {
    setShowNotYetVerified(false);
    setShowWrongCredentials(false);
  };

  const submitCredentials = (e) => {
    e.preventDefault();
    setIsDisabled(true);
    AuthService.authorize(form)
      .then((data) => {
        if (!data.employee && !data.customer) {
          setShowNotYetVerified(true);
        } else {
          dispatch(
            SET_AUTHENTICATED(
              data.token,
              data.employee,
              data.customer,
              data.username,
              data.roles,
              data.fullName
            )
          );
          history.push("/");
        }
      })
      .catch((err) => {
        console.log(err);
        setIsDisabled(false);
        setShowWrongCredentials(true);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <form className={classes.form} onSubmit={submitCredentials}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            onChange={setUsername}
            label="Email"
            name="email"
            autoComplete="off"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            id="password"
            onChange={setPassword}
            autoComplete="off"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isDisabled}
          >
            Вхід
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="http://plg-site.32server.in.ua/" variant="body2">
                Забули пароль?
              </Link>
            </Grid>
            <Grid item>
              <Link href="http://plg-site.32server.in.ua/" variant="body2">
                {"Зареєструватись"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <InfoMessage
        type="error"
        open={showNotYetVerified}
        onClose={handleClose}
        message="Аккаунт ще не пройшов перевірку."
      />
      <InfoMessage
        type="error"
        open={showWrongCredentials}
        onClose={handleClose}
        message="Неправильні дані для входу."
      />
    </Container>
  );
}
