import {Button, Typography} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {makeStyles} from "@material-ui/core/styles";
import {FirstPageOutlined, LinkOutlined, LockOpenOutlined} from "@material-ui/icons";
import RefreshIcon from "@material-ui/icons/Refresh";
import {Field, Form, Formik} from "formik";
import React, {useState} from "react";
import {useQueryClient} from "react-query";
import {useDispatch} from "react-redux";
import * as yup from "yup";
import "./AddOrderForm.css";
import ComboBox from "./ComboBox";
import {SHOW_CUSTOMER_MODAL, UPDATE_SORTING} from "./context/actions";
import store from "./context/store";
import useSaveCELink from "./hooks/useSaveCELink";
import useSaveCPLink from "./hooks/useSaveCPLink";
import useSaveDimensions from "./hooks/useSaveDimensions";
import useSaveOrder from "./hooks/useSaveOrder";
import useSaveOrderKind from "./hooks/useSaveOrderKind";
import useSaveOrderType from "./hooks/useSaveOrderType";
import InfoMessage from "./InfoMessage";
import InputField from "./InputField";
import {CustomerObject} from "./models/CustomerObject";
import {EmptyOrderKind} from "./models/OrderKindObject";
import {EmptyOrder} from "./models/OrderObject";
import {EmptyOrderType} from "./models/OrderTypeObject";
import {EmptySort} from "./models/SortObject";
import {checkPriceShrink} from "./services/utils";
import SwitchControl from "./SwitchControl";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    borderRadius: 4,
    backgroundColor: "white",
    margin: "0 auto",
    width: "52%",
  },
  lock: {
    padding: 0,
    width: "5px",
    backgroundColor: "white !important",
    minWidth: 0,

    "&:hover": {
      backgroundColor: "white",
    },
  },
  svg: {
    fill: "rgba(0, 0, 0, 0.23)",

    "&:hover": {
      fill: "black",
    },
  },
  clear: {
    backgroundColor: "#fff",
    minWidth: 0,
    justifySelf: "flex-end",
    margin: "0px 0px 1px 0",
    width: "2.4rem",
  },
}));

const validationSchema = yup.object({
  order: yup.object({
    entrepreneur: yup.object().required(),
    customer: yup.object().required(),
    orderType: yup.object().required(),
    orderKind: yup.object().required(),
    width: yup.number().lessThan(2000000000).positive().min(0).required(),
    height: yup.number().lessThan(2000000000).positive().min(0).required(),
    amount: yup.number().lessThan(2000000000).positive().min(0).required(),
    price: yup.number().lessThan(2000000000).positive().min(0).required(),
    fees: yup.number().lessThan(2000000000).positive().min(0).required(),
    finalSum: yup.number().lessThan(2000000000).positive().min(0).required(),
    comment: yup.string().trim().max(255).required(),
  }),
});

export default function AddOrderForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const newOrderQuery = useSaveOrder();
  const newCELinkQuery = useSaveCELink();
  const newCPLinkQuery = useSaveCPLink();
  const saveOrderKindQuery = useSaveOrderKind();
  const saveOrderTypeQuery = useSaveOrderType();
  const saveDimensionsQuery = useSaveDimensions();
  const [shrinkStatus, setShrinkStatus] = useState({});
  const [showOrderInfo, setShowOrderInfo] = useState({
    success: false,
    error: false,
  });
  const [showCELinkInfo, setShowCELinkInfo] = useState({
    success: false,
    error: false,
  });
  const [showDimensionsInfo, setShowDimensionsInfo] = useState({
    success: false,
    error: false,
  });
  const [showPriceInfo, setShowPriceInfo] = useState({
    success: false,
    error: false,
  });
  const [showOrderTypeInfo, setShowOrderTypeInfo] = useState({
    success: false,
    error: false,
  });
  const [showOrderKindInfo, setShowOrderKindInfo] = useState({
    success: false,
    error: false,
  });
  const [orderKindName, setOrderKindName] = useState("");
  const [orderTypeName, setOrderTypeName] = useState("");

  const handleClose = () => {
    setShowOrderInfo({success: false, error: false});
    setShowCELinkInfo({success: false, error: false});
    setShowDimensionsInfo({success: false, error: false});
    setShowPriceInfo({success: false, error: false});
    setShowOrderKindInfo({success: false, error: false});
    setShowOrderTypeInfo({success: false, error: false});
  };

  const clearAndUnsort = (e, resetForm) => {
    queryClient.invalidateQueries("customers");
    queryClient.invalidateQueries("ce-links");
    queryClient.invalidateQueries("cp-links");
    store.dispatch(UPDATE_SORTING({...EmptySort}, "main"));
    resetForm(e);
  };

  const onAddCustomer = () => {
    dispatch(SHOW_CUSTOMER_MODAL({...CustomerObject}));
  };

  const onAddOrderType = () => {
    if (orderTypeName.trim().length === 0) {
      setShowOrderTypeInfo({success: false, error: true});
      return;
    }
    saveOrderTypeQuery.mutate({
      orderType: {
        ...EmptyOrderType,
        typeName: orderTypeName,
      },
      setStatus: setShowOrderTypeInfo,
    });
  };

  const onAddOrderKind = (orderType) => {
    if (orderKindName.trim().length === 0 || !orderType) {
      setShowOrderKindInfo({success: false, error: true});
      return;
    }
    saveOrderKindQuery.mutate({
      orderType: orderType,
      orderKind: {
        ...EmptyOrderKind,
        typeId: orderType.typeId,
        kindName: orderKindName,
      },
      setStatus: setShowOrderKindInfo,
    });
  };

  const linkCustomer = (customer, entrepreneur) => {
    if (!customer || !entrepreneur) {
      setShowCELinkInfo({success: false, error: true});
      return;
    }
    newCELinkQuery.mutate({
      customerId: customer.customerId,
      entrepreneurId: entrepreneur.entrepreneurId,
      setStatus: setShowCELinkInfo,
    });
  };

  const linkDimensions = (orderKind, width, height) => {
    if (!orderKind || !width || !height) {
      setShowDimensionsInfo({success: false, error: true});
      return;
    }
    orderKind.width = width;
    orderKind.height = height;
    saveDimensionsQuery.mutate({
      orderKind: orderKind,
      setStatus: setShowDimensionsInfo,
    });
  };

  const linkPrice = (customer, orderKind, price) => {
    if (!customer || !orderKind || !price) {
      setShowPriceInfo({success: false, error: true});
      return;
    }
    newCPLinkQuery.mutate({
      customerId: customer.customerId,
      orderKindId: orderKind.kindId,
      price: price,
      setStatus: setShowPriceInfo,
    });
  };

  const setUrgence = (isUrgently, order) => {
    if (isUrgently) {
      if (order.finalSum * 0.15 < 10) {
        order.finalSum = +order.finalSum + 10;
      } else {
        order.finalSum = Math.ceil(order.finalSum * 1.15);
      }
      order.comment = "(Терміново) " + order.comment;
    }
  };

  const setAcceptance = (onAcceptance, order) => {
    onAcceptance
      ? (order.status = queryClient.getQueryData("statuses").find((status) => status.id === 1))
      : (order.status = queryClient
          .getQueryData("statuses")
          .find((status) => status.id === 2));
  };

  const setNotificationStatus = (notifyWhenReady, order) => {
    order.notifyWhenReady = notifyWhenReady;
  };

  const onFormSubmit = (form, resetForm, setFieldValue) => {
    setUrgence(form.isUrgently, form.order);
    setAcceptance(form.onAcceptance, form.order);
    setNotificationStatus(form.notifyWhenReady, form.order);
    newOrderQuery.mutate({
      newOrder: form.order,
      isReset: form.clearFields,
      resetForm: form.clearFields ? resetForm : () => keepFields(form.order, setFieldValue),
      setStatus: setShowOrderInfo,
    });
  };

  const keepFields = (form, setFieldValue) => {
    setFieldValue("order.width", form.orderKind.width);
    setFieldValue("order.height", form.orderKind.height);
    setFieldValue("order.amount", 0);
    const price =
      queryClient
        .getQueryData("cp-links")
        .find(
          (link) =>
            link.customer?.customerId === form.customer.customerId &&
            link.orderKind?.kindId === form.orderKind.kindId
        )?.price || 0;
    setFieldValue("order.price", price);
    setFieldValue("order.fees", 0);
    setFieldValue("order.finalSum", 0);
    setFieldValue("order.comment", "");
  };

  return (
    <div className={classes.root}>
      <Formik
        validateOnChange={false}
        validateOnMount={false}
        validateOnBlur={false}
        initialValues={{
          order: {
            ...EmptyOrder,
            employee: JSON.parse(localStorage.getItem("loggedUser")),
          },
          onAcceptance: false,
          clearFields: true,
          isUrgently: false,
          notifyWhenReady: false,
        }}
        onSubmit={(form, {resetForm, setFieldValue}) =>
          onFormSubmit(form, resetForm, setFieldValue)
        }
        validationSchema={validationSchema}
      >
        {({values, errors, validateField, handleReset}) => (
          <Form>
            {checkPriceShrink(values.order, shrinkStatus, setShrinkStatus)}
            <div className="row__one">
              <Field
                name="order.customer"
                label="Замовник"
                error={errors?.order?.customer}
                validator={validateField}
                component={ComboBox}
                setEntrepreneur={true}
                setPrice={true}
                sortKey="customer"
                sortPage="main"
                action={onAddCustomer}
                disabled={newOrderQuery.isFetching}
                getName={(option) => option.customerName || ""}
                options={queryClient.getQueryData("customers") || []}
              />
              <Button
                tabIndex={-1}
                className={classes.lock}
                disableRipple={true}
                onClick={() => linkCustomer(values.order.customer, values.order.entrepreneur)}
              >
                <FirstPageOutlined className={classes.svg} />
              </Button>
              <Field
                name="order.entrepreneur"
                label="Підприємець"
                error={errors?.order?.entrepreneur}
                validator={validateField}
                component={ComboBox}
                disabled={newOrderQuery.isFetching}
                getName={(option) => option.fullName || ""}
                options={queryClient.getQueryData("entrepreneurs") || []}
              />
              <Field
                name="order.orderType"
                label="Вид"
                error={errors?.order?.orderType}
                validator={validateField}
                component={ComboBox}
                typeNameSetter={setOrderTypeName}
                action={onAddOrderType}
                disabled={newOrderQuery.isFetching}
                getName={(option) => option.typeName || ""}
                options={queryClient.getQueryData("orderTypes") || []}
              />

              <Field
                name="order.orderKind"
                label="Тип"
                error={errors?.order?.orderKind}
                validator={validateField}
                component={ComboBox}
                setPrice={true}
                setDimensions={true}
                action={() => onAddOrderKind(values.order.orderType)}
                kindNameSetter={setOrderKindName}
                disabled={newOrderQuery.isFetching}
                getName={(option) => option.kindName || ""}
                options={
                  (values.order.orderType?.typeName === "CTP"
                    ? values.order.orderType?.orderKinds.sort((a, b) =>
                        parseInt(a.kindName.substring(0, a.kindName.indexOf("х")), 10) >
                        parseInt(b.kindName.substring(0, b.kindName.indexOf("х")), 10)
                          ? 1
                          : -1
                      ) || []
                    : values.order.orderType?.orderKinds) || []
                }
              />
            </div>

            <div className="row__two">
              <Field
                name="order.width"
                label="Ширина"
                error={errors?.order?.width}
                validator={validateField}
                component={InputField}
                disabled={newOrderQuery.isFetching}
              />
              <Button
                tabIndex={-1}
                className={classes.lock}
                disableRipple={true}
                onClick={() =>
                  linkDimensions(
                    values.order.orderKind,
                    values.order.width,
                    values.order.height
                  )
                }
              >
                <LinkOutlined className={classes.svg} />
              </Button>
              <Field
                name="order.height"
                label="Висота"
                error={errors?.order?.height}
                validator={validateField}
                component={InputField}
                disabled={newOrderQuery.isFetching}
              />
              <Field
                name="order.amount"
                label="Кількість"
                error={errors?.order?.amount}
                validator={validateField}
                component={InputField}
                disabled={newOrderQuery.isFetching}
              />
              <OutlinedInput
                id="outlined"
                size="small"
                variant="outlined"
                value={
                  (
                    ((values.order.width * values.order.height) / 1000000) *
                    values.order.amount
                  ).toFixed(3) || 0.0
                }
                endAdornment={<InputAdornment position="end">м²</InputAdornment>}
                disabled
              />

              <Field
                name="order.price"
                label="Ціна"
                error={errors?.order?.price}
                validator={validateField}
                InputLabelProps={shrinkStatus}
                component={InputField}
                disabled={newOrderQuery.isFetching}
              />
              <Button
                tabIndex={-1}
                className={classes.lock}
                disableRipple={true}
                onClick={() =>
                  linkPrice(values.order.customer, values.order.orderKind, values.order.price)
                }
              >
                <LockOpenOutlined className={classes.svg} />
              </Button>

              <Field
                name="order.fees"
                label="Послуги"
                error={errors?.order?.fees}
                validator={validateField}
                component={InputField}
                disabled={newOrderQuery.isFetching}
              />
              <Field
                name="order.finalSum"
                label="Сума"
                error={errors?.order?.finalSum}
                validator={validateField}
                component={InputField}
                disableCalculation={true}
                disabled={newOrderQuery.isFetching}
              />
            </div>

            <div className="row__three">
              <Field
                name="order.comment"
                label="Опис замовлення"
                error={errors?.order?.comment}
                validator={validateField}
                numeric={false}
                component={InputField}
                disabled={newOrderQuery.isFetching}
              />
            </div>

            <div className="row__four">
              <SwitchControl
                name="onAcceptance"
                isChecked={values.onAcceptance}
                label={
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "0.67rem",
                      color: !values.onAcceptance && "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    На погодженні
                  </Typography>
                }
                disabled={newOrderQuery.isFetching}
              />
              <SwitchControl
                name="clearFields"
                isChecked={values.clearFields}
                label={
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "0.67rem",
                      color: !values.clearFields && "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    Очищати поля
                  </Typography>
                }
                disabled={newOrderQuery.isFetching}
              />
              <SwitchControl
                name="isUrgently"
                isChecked={values.isUrgently}
                label={
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "0.67rem",
                      color: !values.isUrgently && "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    Терміново
                  </Typography>
                }
                disabled={newOrderQuery.isFetching}
              />
              <SwitchControl
                name="notifyWhenReady"
                isChecked={values.notifyWhenReady}
                label={
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "0.67rem",
                      color: !values.notifyWhenReady && "rgba(0, 0, 0, 0.54)",
                    }}
                  >
                    Повідомити про готовність
                  </Typography>
                }
                disabled={newOrderQuery.isFetching}
              />
            </div>

            <div className="row__five">
              <Button
                className="save__button"
                color="primary"
                variant="contained"
                disabled={newOrderQuery.isFetching}
                type="submit"
              >
                Зберегти замовлення
              </Button>

              <Button
                className={classes.clear}
                onClick={(e) => clearAndUnsort(e, handleReset)}
                disabled={newOrderQuery.isFetching}
                variant="contained"
                type="button"
              >
                <RefreshIcon></RefreshIcon>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <InfoMessage
        type="success"
        open={showOrderInfo.success}
        onClose={handleClose}
        message="Замовлення успішно додано."
      />
      <InfoMessage
        type="error"
        open={showOrderInfo.error}
        onClose={handleClose}
        message="Помилка при додаванні замовлення."
      />
      <InfoMessage
        type="success"
        open={showCELinkInfo.success}
        onClose={handleClose}
        message="Зв'язок збережено."
      />
      <InfoMessage
        type="error"
        open={showCELinkInfo.error}
        onClose={handleClose}
        message="Помилка при збереженні зв'язку."
      />
      <InfoMessage
        type="success"
        open={showDimensionsInfo.success}
        onClose={handleClose}
        message="Розміри збережено."
      />
      <InfoMessage
        type="error"
        open={showDimensionsInfo.error}
        onClose={handleClose}
        message="Помилка при збереженні розмірів."
      />
      <InfoMessage
        type="success"
        open={showPriceInfo.success}
        onClose={handleClose}
        message="Ціна прив'язана."
      />
      <InfoMessage
        type="error"
        open={showPriceInfo.error}
        onClose={handleClose}
        message="Помилка при збереженні ціни."
      />
      <InfoMessage
        type="success"
        open={showOrderKindInfo.success}
        onClose={handleClose}
        message="Тип успішно створений."
      />
      <InfoMessage
        type="error"
        open={showOrderKindInfo.error}
        onClose={handleClose}
        message="Помилка при створенні типу. Можливо не вибраний вид."
      />
      <InfoMessage
        type="success"
        open={showOrderTypeInfo.success}
        onClose={handleClose}
        message="Вид успішно створений."
      />
      <InfoMessage
        type="error"
        open={showOrderTypeInfo.error}
        onClose={handleClose}
        message="Помилка при створенні виду."
      />
    </div>
  );
}
