import {Button, Typography} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import {makeStyles} from "@material-ui/core/styles";
import {Field, Form, Formik} from "formik";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import {CLOSE_CUSTOMER_MODAL, SET_MAIN_TAB, UPDATE_SORTING} from "./context/actions";
import useSaveCustomer from "./hooks/useSaveCustomer";
import InfoMessage from "./InfoMessage";
import InputField from "./InputField";
import {EmptySort} from "./models/SortObject";
import SwitchControl from "./SwitchControl";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4),
    width: "40%",

    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "8px !important",
    },
  },
  link: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  h2: {
    textAlign: "center",
    fontWeight: "400",
    marginBottom: "1rem",
  },
  buttons: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr 1fr",
    gap: "2rem",
  },
  clearButton: {
    backgroundColor: "rgb(210,0,10)",
    color: "white",
    marginRight: "1rem",

    "&:hover": {
      backgroundColor: "rgb(190,0,10)",
    },
  },
  rowOne: {
    gridTemplateRows: "1fr 1fr",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridColumnGap: "15px",
    gridRowGap: "10px",
  },
  rowTwo: {
    gridTemplateRows: "1fr 1fr 1fr",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridRowGap: "10px",
  },
  longTextField: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
  billNo: {
    gridColumn: 4,
  },
  switchHolder: {
    gridColumn: 3,
    justifySelf: "end",

    "& .MuiFormControlLabel-root": {
      margin: 0,
    },
  },
  auxiliaryRow: {
    display: "grid",
    gridColumnGap: "20px",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    margin: "2rem auto 1rem auto",

    "& .MuiOutlinedInput-input": {
      padding: "8px",
    },

    "& .MuiButtonBase-root": {
      padding: "2px !important",
    },

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "3px",
    },

    "& #date-picker-inline-label": {
      transform: "translate(14px, 10px) scale(1)",
    },
  },
}));

const validationSchema = yup.object({
  customer: yup.object({
    customerName: yup.string().required(),
    customerGroup: yup.string().nullable(),
    city: yup.string().nullable(),
    info: yup.string().nullable(),
    firstPerson: yup.string().trim().max(25).nullable(),
    firstPhone: yup.string().max(10).min(10).nullable(),
    firstEmail: yup.string().email().nullable(),
    secondPerson: yup.string().trim().max(25).nullable(),
    secondPhone: yup.string().max(10).min(10).nullable(),
    secondEmail: yup.string().email().nullable(),
    thirdPerson: yup.string().trim().max(25).nullable(),
    thirdPhone: yup.string().max(10).min(10).nullable(),
    thirdEmail: yup.string().email().nullable(),
  }),
});

export default function CustomerForm() {
  const classes = useStyles();
  const customerModalState = useSelector((store) => store.showCustomerModal);
  const saveCustomerQuery = useSaveCustomer();
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [showSavedInfo, setShowSavedInfo] = React.useState({
    success: false,
    error: false,
  });

  const handleClose = () => {
    setShowSavedInfo({success: false, error: false});
  };

  const sortByCustomer = () => {
    dispatch(UPDATE_SORTING({...EmptySort, customer: customerModalState.customer}, "sort"));
    dispatch(SET_MAIN_TAB(2));
    dispatch(CLOSE_CUSTOMER_MODAL());
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={customerModalState.show}
        onClose={() => dispatch(CLOSE_CUSTOMER_MODAL())}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={customerModalState.show}>
          <div className={classes.paper}>
            <Formik
              validateOnChange={false}
              validateOnMount={false}
              validateOnBlur={false}
              initialValues={{customer: customerModalState.customer}}
              onSubmit={(form) => {
                saveCustomerQuery.mutate({
                  customer: form.customer,
                  setStatus: setShowSavedInfo,
                });
              }}
              validationSchema={validationSchema}
            >
              {({values, errors, validateField}) => (
                <Form>
                  <Typography className={classes.h2} component="h2" variant="h5">
                    {customerModalState.customer.customerName
                      ? customerModalState.customer.customerName
                      : "Новий замовник"}
                  </Typography>
                  <div className={`row__one ${classes.rowOne}`}>
                    <Field
                      className={classes.longTextField}
                      name="customer.customerName"
                      label="Назва замовника"
                      error={errors?.customer?.customerName}
                      validator={validateField}
                      component={InputField}
                      numeric={false}
                      disabled={saveCustomerQuery.isLoading}
                    />
                    <Field
                      name="customer.customerGroup"
                      label="Група"
                      error={errors?.customer?.customerGroup}
                      validator={validateField}
                      component={InputField}
                      numeric={false}
                      disabled={saveCustomerQuery.isLoading}
                    />
                    <Field
                      className={classes.longTextField}
                      name="customer.info"
                      label="Примітка"
                      error={errors?.customer?.info}
                      validator={validateField}
                      component={InputField}
                      numeric={false}
                      disabled={saveCustomerQuery.isLoading}
                    />
                    <Field
                      name="customer.city"
                      label="Місто"
                      error={errors?.customer?.city}
                      validator={validateField}
                      component={InputField}
                      numeric={false}
                      disabled={saveCustomerQuery.isLoading}
                    />
                  </div>

                  <div className={`${classes.rowTwo} row__two`}>
                    <Field
                      name="customer.firstPerson"
                      label="Конт. Особа 1"
                      error={errors?.customer?.firstPerson}
                      validator={validateField}
                      component={InputField}
                      numeric={false}
                      disabled={saveCustomerQuery.isLoading}
                    />
                    <Field
                      name="customer.firstPhone"
                      label="Телефон (без +38)"
                      error={errors?.customer?.firstPhone}
                      validator={validateField}
                      component={InputField}
                      phone={true}
                      disabled={saveCustomerQuery.isLoading}
                    />
                    <Field
                      name="customer.firstEmail"
                      label="Email"
                      error={errors?.customer?.firstEmail}
                      validator={validateField}
                      component={InputField}
                      numeric={false}
                      disabled={saveCustomerQuery.isLoading}
                    />

                    <Field
                      name="customer.secondPerson"
                      label="Конт. Особа 2"
                      error={errors?.customer?.secondPerson}
                      validator={validateField}
                      component={InputField}
                      numeric={false}
                      disabled={saveCustomerQuery.isLoading}
                    />
                    <Field
                      name="customer.secondPhone"
                      label="Телефон (без +38)"
                      error={errors?.customer?.secondPhone}
                      validator={validateField}
                      component={InputField}
                      phone={true}
                      disabled={saveCustomerQuery.isLoading}
                    />
                    <Field
                      name="customer.secondEmail"
                      label="Email"
                      error={errors?.customer?.secondEmail}
                      validator={validateField}
                      component={InputField}
                      numeric={false}
                      disabled={saveCustomerQuery.isLoading}
                    />

                    <Field
                      name="customer.thirdPerson"
                      label="Конт. Особа 3"
                      error={errors?.customer?.thirdPerson}
                      validator={validateField}
                      component={InputField}
                      numeric={false}
                      disabled={saveCustomerQuery.isLoading}
                    />
                    <Field
                      name="customer.thirdPhone"
                      label="Телефон (без +38)"
                      error={errors?.customer?.thirdPhone}
                      validator={validateField}
                      component={InputField}
                      phone={true}
                      disabled={saveCustomerQuery.isLoading}
                    />
                    <Field
                      name="customer.thirdEmail"
                      label="Email"
                      error={errors?.customer?.thirdEmail}
                      validator={validateField}
                      component={InputField}
                      numeric={false}
                      disabled={saveCustomerQuery.isLoading}
                    />
                  </div>

                  <div className={classes.buttons}>
                    {(values.customer.customerId && (
                      <Button
                        style={{gridColumn: 1}}
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        disabled={saveCustomerQuery.isLoading}
                        onClick={sortByCustomer}
                      >
                        В сортування
                      </Button>
                    )) ||
                      null}

                    <Button
                      style={{gridColumn: 2}}
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      disabled={saveCustomerQuery.isLoading}
                      type="submit"
                    >
                      Зберегти замовника
                    </Button>

                    {(values.customer.customerId && authState.roles.includes("ROLE_ADMIN") && (
                      <div className={classes.switchHolder}>
                        <SwitchControl
                          name="customer.debtor"
                          label="Боржник"
                          isChecked={values.customer.debtor}
                          disabled={saveCustomerQuery.isLoading}
                        />
                      </div>
                    )) ||
                      null}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Fade>
      </Modal>
      <InfoMessage
        type="success"
        open={showSavedInfo.success}
        onClose={handleClose}
        message="Замовинка успішно збережено."
      />
      <InfoMessage
        type="error"
        open={showSavedInfo.error}
        onClose={handleClose}
        message="Помилка при збереженні замовника. Можливо клієнт зі схожою інформацією вже існує."
      />
    </div>
  );
}
