import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Link,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import {makeStyles} from "@material-ui/core/styles";
import {Field, Form, Formik} from "formik";
import React from "react";
import {useQueryClient} from "react-query";
import * as yup from "yup";
import ComboBox from "./ComboBox";
import DatePicker from "./DatePicker";
import useDeleteOrder from "./hooks/useDeleteOrder";
import useUpdateOrder from "./hooks/useUpdateOrder";
import InfoMessage from "./InfoMessage";
import InputField from "./InputField";
import ToolTip from "./ToolTip";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4),
    width: "55%",
  },
  link: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  h2: {
    top: "-.9rem",
    position: "relative",
    marginTop: 0,
    textAlign: "center",
    fontWeight: "400",
    gridColumnEnd: 4,
    gridColumnStart: 2,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  clearButton: {
    backgroundColor: "rgb(210,0,10)",
    color: "white",
    marginRight: "1rem",

    "&:hover": {
      backgroundColor: "rgb(190,0,10)",
    },
  },
  rowOne: {
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
  },
  rowTwo: {
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
  },
  rowThree: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "black",
    },
  },
  billNo: {
    gridColumn: 4,
  },
  auxiliaryRow: {
    display: "grid",
    gridColumnGap: "20px",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    margin: "2rem auto 1rem auto",

    "& .MuiOutlinedInput-input": {
      padding: "8px",
    },

    "& .MuiButtonBase-root": {
      padding: "2px !important",
    },

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "3px",
    },

    "& #date-picker-inline-label": {
      transform: "translate(14px, 10px) scale(1)",
    },
  },
}));

const validationSchema = yup.object({
  order: yup.object({
    entrepreneur: yup.object().required(),
    customer: yup.object().required(),
    date: yup.date().required(),
    orderType: yup.object().required(),
    orderKind: yup.object().required(),
    billNo: yup.number().lessThan(2000000000).positive().min(0),
    width: yup.number().lessThan(2000000000).positive().min(0).required(),
    height: yup.number().lessThan(2000000000).positive().min(0).required(),
    amount: yup.number().lessThan(2000000000).positive().min(0).required(),
    price: yup.number().lessThan(2000000000).positive().min(0).required(),
    fees: yup.number().lessThan(2000000000).positive().min(0).required(),
    finalSum: yup.number().lessThan(2000000000).positive().min(0).required(),
    comment: yup.string().trim().max(255).required(),
    payLog: yup.string(),
  }),
});

export default function EditForm({user, roles, order}) {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const deleteQuery = useDeleteOrder();
  const updateOrderQuery = useUpdateOrder();
  const [open, setOpen] = React.useState(false);
  const [showDeleteError, setShowDeleteError] = React.useState(false);
  const [showSaveError, setShowSaveError] = React.useState(false);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const toggle = () => setOpen(!open);

  const onDeleteOrder = (orderId) => {
    setShowConfirmation(false);
    deleteQuery.mutate({
      orderId: orderId,
      setStatus: setShowSaveError,
      setShowModal: setOpen,
    });
  };

  const isNotMyOrder = () => {
    if (!user) return false;
    return order.employee.employeeID !== user.employeeID;
  };

  const amI = (role) => {
    return roles.includes(role);
  };

  const isDisabledFor = (role = "ROLE_ADMIN") => {
    if (!user) return true;
    if (amI("ROLE_ADMIN")) return false;
    return amI(role) ? false : isNotMyOrder();
  };

  return (
    <div>
      <ToolTip title={order.employee.name}>
        <Link className={classes.link} onClick={toggle}>
          <b>{order.orderId}</b>
        </Link>
      </ToolTip>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={toggle}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Formik
              validateOnChange={true}
              validateOnMount={false}
              validateOnBlur={false}
              initialValues={{order: order}}
              onSubmit={(form) => {
                updateOrderQuery.mutate({
                  order: form.order,
                  toggleModal: toggle,
                  setStatus: setShowSaveError,
                });
              }}
              validationSchema={validationSchema}
            >
              {({values, errors, validateField}) => (
                <Form>
                  <InfoMessage
                    type="error"
                    open={showDeleteError}
                    onClose={() => setShowDeleteError(false)}
                    message="Помилка при видаленні замовлення."
                  />
                  <InfoMessage
                    type="error"
                    open={showSaveError}
                    onClose={() => setShowSaveError(false)}
                    message="Помилка при збереженні замовлення."
                  />
                  <div className={classes.auxiliaryRow}>
                    <Field
                      component={DatePicker}
                      label="Дата"
                      name="order.date"
                      error={errors?.order?.date}
                      validator={validateField}
                      disabled={updateOrderQuery.isLoading || isDisabledFor()}
                    />
                    <Typography className={classes.h2} component="h2" variant="h5">
                      Замовлення № <b>{order.orderId}</b>
                    </Typography>
                    <Field
                      className={classes.billNo}
                      name="order.billNo"
                      label="№ Рахунку"
                      disableCalculation={true}
                      error={errors?.order?.billNo}
                      validator={validateField}
                      component={InputField}
                      disabled={updateOrderQuery.isLoading || isDisabledFor("ROLE_MANAGER")}
                    />
                  </div>

                  <div className={`${classes.rowOne} row__one`}>
                    <Field
                      name="order.entrepreneur"
                      label="Підприємець"
                      error={errors?.order?.entrepreneur}
                      validator={validateField}
                      component={ComboBox}
                      disabled={updateOrderQuery.isLoading || isDisabledFor()}
                      getName={(option) => option.fullName || ""}
                      options={queryClient.getQueryData("entrepreneurs")}
                    />
                    <Field
                      name="order.customer"
                      label="Замовник"
                      error={errors?.order?.customer}
                      validator={validateField}
                      component={ComboBox}
                      disabled={updateOrderQuery.isLoading || isDisabledFor()}
                      getName={(option) => option.customerName || ""}
                      options={queryClient.getQueryData("customers")}
                    />
                    <Field
                      name="order.orderType"
                      label="Вид"
                      error={errors?.order?.orderType}
                      validator={validateField}
                      component={ComboBox}
                      disabled={updateOrderQuery.isLoading || isDisabledFor()}
                      getName={(option) => option.typeName || ""}
                      options={queryClient.getQueryData("orderTypes")}
                    />
                    <Field
                      name="order.orderKind"
                      label="Тип"
                      error={errors?.order?.orderKind}
                      validator={validateField}
                      component={ComboBox}
                      disabled={updateOrderQuery.isLoading || isDisabledFor()}
                      getName={(option) => option.kindName || ""}
                      options={values.order?.orderType?.orderKinds || []}
                    />
                  </div>

                  <div className={`${classes.rowTwo} row__two`}>
                    <Field
                      name="order.width"
                      label="Ширина"
                      error={errors?.order?.width}
                      validator={validateField}
                      component={InputField}
                      disabled={updateOrderQuery.isLoading || isDisabledFor()}
                    />
                    <Field
                      name="order.height"
                      label="Висота"
                      error={errors?.order?.height}
                      validator={validateField}
                      component={InputField}
                      disabled={updateOrderQuery.isLoading || isDisabledFor()}
                    />
                    <Field
                      name="order.amount"
                      label="Кількість"
                      error={errors?.order?.amount}
                      validator={validateField}
                      component={InputField}
                      disabled={updateOrderQuery.isLoading || isDisabledFor()}
                    />
                    <OutlinedInput
                      id="metrics"
                      size="small"
                      variant="outlined"
                      value={
                        (
                          ((values.order.width * values.order.height) / 1000000) *
                          values.order.amount
                        ).toFixed(3) || 0.0
                      }
                      endAdornment={<InputAdornment position="end">м²</InputAdornment>}
                      disabled
                    />
                    <Field
                      name="order.price"
                      label="Ціна"
                      error={errors?.order?.price}
                      validator={validateField}
                      component={InputField}
                      disabled={updateOrderQuery.isLoading || isDisabledFor()}
                    />
                    <Field
                      name="order.fees"
                      label="Послуги"
                      error={errors?.order?.fees}
                      validator={validateField}
                      component={InputField}
                      disabled={updateOrderQuery.isLoading || isDisabledFor()}
                    />
                    <Field
                      name="order.finalSum"
                      label="Сума"
                      error={errors?.order?.finalSum}
                      validator={validateField}
                      component={InputField}
                      disableCalculation={true}
                      disabled={updateOrderQuery.isLoading || isDisabledFor()}
                    />
                  </div>

                  <div className="row__three">
                    <Field
                      name="order.comment"
                      label="Опис замовлення"
                      error={errors?.order?.comment}
                      validator={validateField}
                      numeric={false}
                      component={InputField}
                      disabled={updateOrderQuery.isLoading || isDisabledFor("ROLE_MANAGER")}
                    />
                  </div>

                  <div className={`${classes.rowThree} row__three`}>
                    <Field
                      name="order.payLog"
                      label="Історія оплат"
                      error={errors?.order?.payLog}
                      validator={validateField}
                      numeric={false}
                      multiline={true}
                      component={InputField}
                      disabled={updateOrderQuery.isLoading || isDisabledFor()}
                    />
                  </div>

                  {user && (
                    <div className={classes.buttons}>
                      <Button
                        className={classes.clearButton}
                        disabled={updateOrderQuery.isLoading || isDisabledFor()}
                        variant="contained"
                        type="button"
                        onClick={() => setShowConfirmation(true)}
                      >
                        Видалити
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        disabled={updateOrderQuery.isLoading || isDisabledFor("ROLE_MANAGER")}
                        type="submit"
                      >
                        Зберегти замовлення
                      </Button>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </Fade>
      </Modal>
      <Dialog
        fullScreen={false}
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Ви дійсно бажаєте видалити це замовлення?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Повернути його зміст та оплати буде неможливо. Підтвердити?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setShowConfirmation(false)}
            variant="contained"
            color="primary"
          >
            Ні
          </Button>
          <Button
            onClick={() => onDeleteOrder(order.orderId)}
            variant="contained"
            className={classes.clearButton}
            autoFocus
          >
            Так
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
