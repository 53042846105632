import * as ActionTypes from "./actionTypes";

export const NEW_ORDER = (newOrder) => ({
  type: ActionTypes.ADD_ORDER,
  payload: newOrder,
});

export const LOAD_ORDERS = (orders) => ({
  type: ActionTypes.LOAD_ORDERS,
  payload: orders,
});

export const SET_AUTHENTICATED = (token, employee, customer, username, roles, fullName) => ({
  type: ActionTypes.SET_AUTHENTICATED,
  payload: {
    token,
    employee,
    customer,
    username,
    roles,
    fullName,
  },
});

export const SET_UNAUTHENTICATED = () => ({
  type: ActionTypes.SET_UNAUTHENTICATED,
});

export const RESET_SELECTIONS = () => ({
  type: ActionTypes.RESET_SELECTIONS,
});

export const SELECT_CUSTOMER = (customer) => ({
  type: ActionTypes.SELECT_CUSTOMER,
  payload: customer,
});

export const DESELECT_CUSTOMER = () => ({
  type: ActionTypes.UNSELECT_CUSTOMER,
});

export const SET_ACTIVE_SORTING = (sorting) => ({
  type: ActionTypes.SET_ACTIVE_SORTING,
  payload: sorting,
});

export const ADD_SORTING_BY = (selection, type) => {
  let sortPage;
  switch (type) {
    case "main":
      sortPage = ActionTypes.ADD_MAIN_SORTING;
      break;
    case "sort":
      sortPage = ActionTypes.ADD_SORT_SORTING;
      break;
    case "payment":
      sortPage = ActionTypes.ADD_PAYMENT_SORTING;
      break;
    case "filter":
      sortPage = ActionTypes.ADD_FILTER_SORTING;
      break;
    default:
      break;
  }
  return {
    type: sortPage,
    payload: selection,
  };
};

export const UPDATE_SORTING = (selections, type) => {
  let sortPage;
  switch (type) {
    case "main":
      sortPage = ActionTypes.UPDATE_MAIN_SORTING;
      break;
    case "sort":
      sortPage = ActionTypes.UPDATE_SORT_SORTING;
      break;
    case "payment":
      sortPage = ActionTypes.UPDATE_PAYMENT_SORTING;
      break;
    case "filter":
      sortPage = ActionTypes.UPDATE_FILTER_SORTING;
      break;
    default:
      break;
  }
  return {
    type: sortPage,
    payload: selections,
  };
};

export const SHOW_CUSTOMER_MODAL = (customer = {}) => {
  return {
    type: ActionTypes.SHOW_CUSTOMER_MODAL,
    payload: customer,
  };
};

export const SET_MAIN_TAB = (id) => ({
  type: ActionTypes.SET_MAIN_TAB,
  payload: id,
});

export const CLOSE_CUSTOMER_MODAL = () => ({
  type: ActionTypes.CLOSE_CUSTOMER_MODAL,
});

export const SET_FILTER_OPENED = () => ({
  type: ActionTypes.SET_FILTER_OPENED,
});

export const SET_FILTER_CLOSED = () => ({
  type: ActionTypes.SET_FILTER_CLOSED,
});
