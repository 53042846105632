import Axios from "axios";
import {useMutation} from "react-query";

export default function useExportCustomers() {
  return useMutation(
    (customers) =>
      Axios.post(
        "api/customers/export/",
        {customers: customers},
        {
          responseType: "arraybuffer",
        }
      ),
    {
      onError: (_, params) => {
        params.setStatus({success: false, error: true});
      },
      onSuccess: (val, _) => {
        const blob = new Blob([val.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = data;
        link.download = "Клієнти.xlsx";
        link.dispatchEvent(
          new MouseEvent("click", {bubbles: true, cancelable: true, view: window})
        );
      },
    }
  );
}
