import {makeStyles, Tooltip} from "@material-ui/core";
import React from "react";

const useStylesTooltip = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    fontSize: 12,
    backgroundColor: theme.palette.primary.main,
  },
}));

function ToolTip({title, value, placement = "right-start", children, ...props}) {
  const classes = useStylesTooltip();

  return (
    <Tooltip title={title} placement={placement} arrow classes={classes} {...props}>
      <div className="value" style={{lineHeight: "1.43"}}>
        {children}
      </div>
    </Tooltip>
  );
}

export default ToolTip;
