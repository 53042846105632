import {Button, Checkbox} from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import useSetStatus from "./hooks/useSetStatus";
import useNotificationStatus from "./hooks/useNotificationStatus";

const sources = [
  "",
  "img/on-agreement.svg",
  "img/in-progress.svg",
  "img/ready.svg",
  "img/completed.svg",
];

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    textAlign: "center",
    backgroundColor: "#fff",
    boxShadow: "0 0 14px 2px rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
  },
  acceptanceButton: {
    width: "100%",
    margin: ".2rem 0",
    backgroundColor: "#fff",
    border: "1px solid",
    borderColor: "#df16a0",
    color: "#df16a0",

    "&:hover": {
      color: "#fff!important",
      backgroundColor: "#df16a0",
    },
  },
  inWorkButton: {
    width: "100%",
    margin: ".2rem 0",
    backgroundColor: "#fff",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,

    "&:hover": {
      color: "#fff!important",
      backgroundColor: theme.palette.primary.main,
    },
  },
  readyButton: {
    width: "100%",
    backgroundColor: "#fff",
    border: "1px solid",
    borderColor: "#28a745",
    margin: ".2rem 0",
    color: "#28a745",
    display: "grid",

    "&:hover": {
      color: "#fff!important",
      backgroundColor: "#28a745",
    },
  },
  completedButton: {
    width: "100%",
    margin: ".2rem 0",
    backgroundColor: "#28a745",
    border: "1px solid",
    borderColor: "#218838",
    color: "#fff!important",

    "&:hover": {
      borderColor: "#21883861",
      backgroundColor: "#218838",
    },
  },
  popper: {
    zIndex: 1,
    marginLeft: "0.5rem",
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      marginTop: "0.45rem",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      marginTop: "0.45rem",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  notificationBlock: {
    padding: "0 .1rem 0 0",
    "& *": {
      padding: "0rem",
    },
    "& span": {
      verticalAlign: "-webkit-baseline-middle",
      fontSize: "0.6rem",
      marginRight: "0.1rem",
    },
    "& .MuiIconButton-label > *": {
      height: "1.1rem",
      width: "1.1rem",
    },
  },
  enabled: {
    "& span": {
      color: "#28a745",
    },
  },
}));

function StatusPopper({order, disabled = false}) {
  const classes = useStyles();
  const statusQuery = useSetStatus();
  const notificationStatusQuery = useNotificationStatus();
  const anchorRef = React.useRef(null);
  const popperRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const [arrow] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [arrowRef, setArrowRef] = React.useState(null);
  const id = open ? "scroll-playground" : null;

  const clickOff = (evt) => {
    if (!popperRef.current) {
      setOpen(true);
      return;
    }

    if (!popperRef.current.contains(evt.target)) {
      document.removeEventListener("click", clickOff);
      setOpen(false);
    }
  };

  const show = () => {
    document.addEventListener("click", clickOff);
  };

  const hide = () => {
    document.dispatchEvent(new Event("click"));
  };

  return (
    <>
      <img
        className={classes.img}
        ref={anchorRef}
        src={sources[order.status.id]}
        width="19"
        height="19"
        alt="on-agreement"
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={show}
      />

      <Popper
        id={id}
        open={open}
        anchorEl={anchorRef.current}
        ref={popperRef}
        placement="right"
        disablePortal={true}
        className={classes.popper}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        {arrow ? <span className={classes.arrow} ref={setArrowRef} /> : null}
        <div className={classes.container}>
          <Button
            ref={buttonRef}
            className={classes.acceptanceButton}
            onClick={() =>
              statusQuery.mutate({
                orderId: order.orderId,
                statusId: 1,
                toggle: hide,
                notifyManager: false,
              })
            }
          >
            На погодженні
          </Button>
          <br />
          <Button
            className={classes.inWorkButton}
            onClick={() =>
              statusQuery.mutate({
                orderId: order.orderId,
                statusId: 2,
                toggle: hide,
                notifyManager: false,
              })
            }
          >
            В роботі
          </Button>
          <br />
          <div className={classes.readyButtonContainer}>
            <Button
              className={classes.readyButton}
              onClick={() =>
                statusQuery.mutate({
                  orderId: order.orderId,
                  statusId: 3,
                  toggle: hide,
                })
              }
            >
              Готове
            </Button>
          </div>
          <Button
            className={classes.completedButton}
            onClick={() =>
              statusQuery.mutate({
                orderId: order.orderId,
                statusId: 4,
                toggle: hide,
                notifyManager: false,
              })
            }
          >
            Віддано
          </Button>

          <div
            className={`${classes.notificationBlock} ${
              order.notifyWhenReady && classes.enabled
            }`}
          >
            <Checkbox
              color="primary"
              checked={order.notifyWhenReady}
              onChange={() => {
                order.notifyWhenReady = !order.notifyWhenReady;
                notificationStatusQuery.mutate({
                  orderId: order.orderId,
                });
              }}
              disableRipple={true}
            />
            <span>
              Повідомляти <b>psdruk@gmail.com</b> про готовність
            </span>
          </div>
        </div>
      </Popper>
    </>
  );
}

export default StatusPopper;
