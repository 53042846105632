export const EmptyFilter = {
  entrepreneur: null,
  customer: null,
  status: null,
  employee: null,
  dateFrom: null,
  dateTill: null,
  payDateFrom: null,
  payDateTill: null,
  orderKind: null,
  orderType: null,
  receiver: "",
};
