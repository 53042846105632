import Axios from "axios";
import {useMutation} from "react-query";

export default function useNotificationStatus() {
  return useMutation(
    (params) => Axios.put("/api/statuses/notificationStatus/" + params.orderId),
    {
      onSuccess: (_) => {},
    }
  );
}
