import DateFnsUtils from "@date-io/date-fns";
import {formatISO} from "date-fns";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import {ukUA} from "@material-ui/core/locale";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {uk} from "date-fns/locale";
import React from "react";
import ReactDOM from "react-dom";
import {QueryCache, QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import {Provider} from "react-redux";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import App from "./App";
import store from "./context/store";
import "./index.css";
import Login from "./Login";
import NotFound from "./NotFound";
import {ProtectedRoute} from "./ProtectedRoute";

const oldTheme = createMuiTheme(
  {
    palette: {
      primary: {
        light: "#fff",
        main: "rgba(0, 60, 170, 0.95)",
        dark: "rgba(0, 40, 170, 0.95)",
      },
      secondary: {
        main: "#f44336",
      },
    },
    typography: {
      useNextVariants: true,
      fontSize: 11,
      htmlFontSize: 15,
    },
  },
  ukUA
);

const {breakpoints} = oldTheme;
const theme = {
  ...oldTheme,
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: 11,
        [breakpoints.up("lg")]: {
          fontSize: 11,
        },
        [breakpoints.down(1300)]: {
          fontSize: 9,
        },
      },
    },
    MuiInputAdornment: {
      root: {
        fontSize: 11,
        [breakpoints.up("lg")]: {
          fontSize: 11,
        },
        [breakpoints.down(1300)]: {
          fontSize: 9,
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 11,
        [breakpoints.up("lg")]: {
          fontSize: 11,
        },
        [breakpoints.down(1300)]: {
          fontSize: 9,
        },
      },
    },
    MuiTypography: {
      root: {
        fontSize: 11,
        [breakpoints.up("lg")]: {
          fontSize: 11,
        },
        [breakpoints.down(1300)]: {
          fontSize: 9,
        },
      },
      subtitle1: {
        fontSize: 11,
        [breakpoints.up("lg")]: {
          fontSize: 11,
        },
        [breakpoints.down(1300)]: {
          fontSize: 9,
        },
      },
      body1: {
        fontSize: 12,
        [breakpoints.up("lg")]: {
          fontSize: 12,
        },
        [breakpoints.down(1300)]: {
          fontSize: 10,
        },
      },
      button: {
        fontSize: 11,
        [breakpoints.up("lg")]: {
          fontSize: 11,
        },
        [breakpoints.down(1300)]: {
          fontSize: 9,
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: 11,
        [breakpoints.up("lg")]: {
          fontSize: 11,
        },
        [breakpoints.down(1300)]: {
          fontSize: 9,
        },
      },
    },
    MuiTab: {
      root: {
        fontSize: 11,
        [breakpoints.up("lg")]: {
          fontSize: 11,
        },
        [breakpoints.down(1300)]: {
          fontSize: 9,
        },
      },
    },
    MuiSvgIcon: {
      root: {
        [breakpoints.down(1300)]: {
          fontSize: 15,
        },
      },
    },
  },
};

// eslint-disable-next-line no-extend-native
Date.prototype.toISOString = function () {
  const date = formatISO(this);
  return date.substr(0, date.indexOf("+"));
};

const queryCache = new QueryCache();
const queryClient = new QueryClient({queryCache});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={uk}>
        <Provider store={store}>
          <Router>
            <Switch>
              <Route path="/login" component={Login} />
              <ProtectedRoute path="/" component={App} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Router>
        </Provider>
      </MuiPickersUtilsProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
