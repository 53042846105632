import {Button, Collapse, Typography} from "@material-ui/core";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import "./ActionBar.css";
import AddOrderForm from "./AddOrderForm";
import {SET_ACTIVE_SORTING, SET_MAIN_TAB} from "./context/actions";
import PaymentForm from "./PaymentForm";
import SortForm from "./SortForm";

export default function ActionBar() {
  const dispatch = useDispatch();
  const authState = useSelector((store) => store.auth);
  const sortState = useSelector((state) => state.sortSelections);

  const open = (tabId) => {
    if (sortState.mainTab === tabId) {
      dispatch(SET_MAIN_TAB(0));
    } else {
      dispatch(SET_MAIN_TAB(tabId));
    }
  };

  const hideFor = (roles) => !roles.some((role) => authState.roles.includes(role));

  const setSortView = (type) => {
    if (sortState.active === type) return;
    dispatch(SET_ACTIVE_SORTING(type));
  };

  const showCustomerInfo = () => {
    if (!sortState[sortState.active].customer) return;
    return (
      <Typography variant="subtitle1">
        {sortState[sortState.active].customer?.debtor && (
          <img
            className="debtor-mark"
            src="img/Blackmark.svg"
            width="19"
            height="19"
            alt="blackmark"
          />
        )}
        <strong>{sortState[sortState.active].customer?.customerName}</strong>{" "}
        {sortState[sortState.active].customer?.customerGroup}:{" "}
        {sortState[sortState.active].customer?.info}
      </Typography>
    );
  };

  return (
    <div>
      <div className="action_bar_container">
        <div className="action__bar">
          <div className="action__bar__info">{showCustomerInfo()}</div>
          <div className="action__bar__button">
            {hideFor(["ROLE_GROUNDFLOOR", "ROLE_UVPRINT", "ROLE_CUSTOMER"]) && (
              <Button
                variant="contained"
                color={sortState.mainTab === 1 ? "" : "primary"}
                onClick={() => open(1)}
              >
                Додати замовлення
              </Button>
            )}
            {hideFor(["ROLE_CUSTOMER"]) && (
              <Button
                style={{marginLeft: ".5rem"}}
                variant="contained"
                color={sortState.mainTab === 2 ? "" : "primary"}
                onClick={() => open(2)}
              >
                Сортування
              </Button>
            )}
            {hideFor(["ROLE_GROUNDFLOOR", "ROLE_UVPRINT", "ROLE_CUSTOMER"]) && (
              <Button
                style={{marginLeft: ".5rem"}}
                variant="contained"
                color={sortState.mainTab === 3 ? "" : "primary"}
                onClick={() => open(3)}
              >
                Внести оплату
              </Button>
            )}
          </div>
        </div>

        <Collapse
          in={sortState.mainTab === 1}
          timeout={300}
          onEntered={() => setSortView("main")}
          unmountOnExit
        >
          <AddOrderForm />
        </Collapse>

        <Collapse
          in={sortState.mainTab === 2}
          timeout={300}
          onEntered={() => setSortView("sort")}
          unmountOnExit
        >
          <SortForm />
        </Collapse>

        <Collapse
          in={sortState.mainTab === 3}
          timeout={300}
          onEntered={() => setSortView("payment")}
          unmountOnExit
        >
          <PaymentForm />
        </Collapse>
      </div>
    </div>
  );
}
