import {Link, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import React from "react";
import {useQueryClient} from "react-query";
import {useSelector, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {SET_ACTIVE_SORTING} from "./context/actions";
import "./Header.css";
import * as AuthService from "./services/auth-service";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  logoutButton: {
    padding: 0,
  },
  logoutHolder: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "baseline",
    whiteSpace: "nowrap",
  },
  tabs: {
    margin: "0 auto",
    cursor: "pointer",
  },
}));

export default function Header({radius}) {
  const classes = useStyles();
  const history = useHistory();
  const authState = useSelector((state) => state.auth);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const sortState = useSelector((state) => state.sortSelections);
  const [value, setValue] = React.useState(0);

  const logout = () => {
    AuthService.unauthorize();
    history.push("/login");
  };

  const setSortView = (type) => {
    if (sortState.active === type) return;
    dispatch(SET_ACTIVE_SORTING(type));
  };

  const changeView = (_e, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        history.push("/");
        break;
      case 1:
        setSortView("filter");
        history.push("/sort");
        break;
      case 2:
        queryClient.invalidateQueries("customers");
        history.push("/clients");
        break;
      case 3:
        queryClient.invalidateQueries("users");
        queryClient.invalidateQueries("customers");
        history.push("/users");
        break;
      case 4:
        queryClient.invalidateQueries("debtors");
        history.push("/debtors");
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="header__container">
        <div
          className="menu"
          style={{borderBottomLeftRadius: radius, borderBottomRightRadius: radius}}
        >
          <Toolbar>
            <Link color="inherit" href="https://psdruk.com.ua/">
              <img
                width="60"
                height="50"
                src="/img/logo_new.svg"
                style={{verticalAlign: "middle"}}
                alt="logo"
              />
            </Link>
            {!authState.roles.includes("ROLE_CUSTOMER") ? (
              <>
                <Tabs
                  className={classes.tabs}
                  value={value}
                  onChange={changeView}
                  TabIndicatorProps={{style: {background: "white"}}}
                  indicatorColor="secondary"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Головна" value={0} />
                  {authState.roles.includes("ROLE_ADMIN") && (
                    <Tab label="Сортування" value={1} />
                  )}
                  <Tab label="Клієнти" value={2} />
                  {authState.roles.includes("ROLE_ADMIN") && (
                    <Tab label="Користувачі" value={3} />
                  )}
                  {authState.roles.includes("ROLE_ADMIN") && (
                    <Tab label="Боржники" value={4} />
                  )}
                </Tabs>
              </>
            ) : (
              <div className={classes.tabs}></div>
            )}

            <div className={classes.logoutHolder}>
              <Typography variant="body1" style={{fontWeight: 300}}>
                Вітаємо, <b>{localStorage.getItem("fullName")}</b>
              </Typography>
              <Button color="inherit" onClick={logout} className={classes.logoutButton}>
                Вийти
              </Button>
            </div>
          </Toolbar>
        </div>
      </div>
    </div>
  );
}
