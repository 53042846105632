import {Button, Link, TextField} from "@material-ui/core";
import {XGrid} from "@material-ui/x-grid";
import React, {useState} from "react";
import {useQueryClient} from "react-query";
import {useSelector} from "react-redux";
import "./ClientTable.css";
import {SHOW_CUSTOMER_MODAL} from "./context/actions";
import store from "./context/store";
import useExportCustomers from "./hooks/useExportCustomers";
import {fixCustomers} from "./services/utils";

const columns = [
  {
    field: "customerName",
    headerName: "Назва замовника",
    flex: 3,
    renderCell: (params) => (
      <Link
        className="name-link"
        onClick={() => store.dispatch(SHOW_CUSTOMER_MODAL(params.row))}
      >
        <b>{params.value}</b>
      </Link>
    ),
  },
  {
    field: "debtor",
    headerName: "Боржник",
    flex: 1.4,
    cellClassName: "centered",
    renderCell: (params) => {
      if (params.value) {
        return <img src="img/Blackmark.svg" width="19" height="19" alt="blackmark" />;
      } else return <div></div>;
    },
  },
  {
    field: "customerGroup",
    headerName: "Група",
    flex: 5,
  },
  {
    field: "firstPerson",
    headerName: "Особа 1",
    flex: 2,
  },
  {
    field: "firstPhone",
    headerName: "Телефон 1",
    flex: 2.5,
  },
  {
    field: "firstEmail",
    headerName: "Email 1",
    flex: 4,
  },
  {
    field: "city",
    headerName: "Місто",
    flex: 1.3,
  },
  {
    field: "info",
    headerName: "Інформація",
    flex: 6,
  },
];

export default function ClientsTable() {
  const queryClient = useQueryClient();
  const exportQuery = useExportCustomers();
  const authState = useSelector((state) => state.auth);
  const [q, setQ] = useState("");

  const filter = (rows) => {
    return rows.filter(
      (row) =>
        row.customerName?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.customerGroup?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.firstPerson?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.firstPhone?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.firstEmail?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.city?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.info?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
    );
  };

  return (
    <>
      <TextField
        variant="outlined"
        size="small"
        placeholder="Швидкий пошук"
        onChange={(e) => setQ(e.target.value)}
        InputProps={{
          endAdornment: authState.roles.includes("ROLE_ADMIN") && (
            <Button
              className="export-btn"
              variant="outlined"
              onClick={() =>
                exportQuery.mutate(
                  filter(fixCustomers(queryClient.getQueryData("customers") || []))
                )
              }
            >
              Експорт
            </Button>
          ),
        }}
      />
      <div className="x-grid">
        <div className="frame">
          <div className="grid-holder">
            <XGrid
              className="customers-grid"
              rows={filter(fixCustomers(queryClient.getQueryData("customers") || []))}
              columns={columns}
              sortModel={[{field: "customerName", sort: "asc"}]}
              loading={queryClient.isFetching("customers")}
              rowHeight={35}
              headerHeight={35}
              pagination={true}
              disableSelectionOnClick={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
