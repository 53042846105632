import {useQuery} from "react-query";
import {useSelector} from "react-redux";
import * as CustomerService from ".././services/customer-service";

export default function useCPLinks() {
  const authState = useSelector((state) => state.auth);
  return useQuery("cp-links", () => CustomerService.fetchCPLinks(authState.roles), {
    initialData: [],
    initialStale: true,
    keepPreviousData: true,
  });
}
