import {makeStyles} from "@material-ui/core";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {fieldToTextField} from "formik-material-ui";
import React from "react";

const useStyles = makeStyles(() => ({
  datepicker: {
    margin: 0,

    "& .MuiButtonBase-root": {
      padding: 2,
    },

    "& .MuiOutlinedInput-input": {
      padding: 8,
    },

    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 10px) scale(1)",
    },

    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -4px) scale(0.75)",
    },

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 3,
    },

    "& .MuiInputAdornment-root": {
      width: "25%",
      marginLeft: 0,
      justifyContent: "flex-end",
    },
  },
}));

function DatePicker({label, validator, onTouch = false, ...props}) {
  const classes = useStyles();
  const {
    form: {setFieldValue},
  } = props;
  const {...field} = fieldToTextField(props);
  const {name} = field;

  return (
    <KeyboardDatePicker
      {...props}
      {...field}
      autoOk={true}
      autoComplete="off"
      helperText={""}
      className={classes.datepicker}
      inputVariant="outlined"
      variant="inline"
      format="dd/MM/yyyy"
      margin="normal"
      label={label}
      onChange={(value) => {
        setFieldValue(name, value);

        if (onTouch) {
          onTouch(true);
        }

        if (validator) {
          validator(name);
        }
      }}
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
    />
  );
}

export default DatePicker;
