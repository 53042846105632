import Axios, {CancelToken} from "axios";

export const fetchCustomers = async (roles) => {
  if (roles.includes("ROLE_CUSTOMER")) return [];
  const source = CancelToken.source();
  const res = await Axios.get("/api/customers/", {
    cancelToken: source.token,
  });
  res.cancel = () => source.cancel("Query was cancelled by React Query");
  return res.data;
};

export const fetchEntrepreneurs = async (roles) => {
  if (roles.includes("ROLE_CUSTOMER")) return [];
  const res = await Axios.get("/api/entrepreneurs/");
  return res.data;
};

export const fetchUsers = async (roles) => {
  if (roles.includes("ROLE_CUSTOMER")) return [];
  const res = await Axios.get("/api/users/");
  return res.data;
};

export const fetchOrderTypes = async (roles) => {
  if (roles.includes("ROLE_CUSTOMER")) return [];
  const res = await Axios.get("/api/order-types/");
  return res.data;
};

export const fetchOrderKinds = async (roles) => {
  if (roles.includes("ROLE_CUSTOMER")) return [];
  const res = await Axios.get("/api/order-kinds/");
  return res.data;
};

export const fetchStatuses = async (roles) => {
  if (roles.includes("ROLE_CUSTOMER")) return [];
  const res = await Axios.get("/api/statuses/");
  return res.data;
};

export const fetchEmployees = async (roles) => {
  if (roles.includes("ROLE_CUSTOMER")) return [];
  const res = await Axios.get("/api/employees/");
  return res.data;
};

export const fetchDebtors = async (roles) => {
  if (roles.includes("ROLE_CUSTOMER")) return [];
  const res = await Axios.get("/api/customers/debtors/");
  return res.data;
};

export const fetchPaymentMethods = async () => {
  const res = await Axios.get("/api/payments/");
  return res.data;
};

export const fetchCELinks = async (roles) => {
  if (roles.includes("ROLE_CUSTOMER")) return [];
  const source = CancelToken.source();
  const res = await Axios.get("/api/ce-links/", {
    cancelToken: source.token,
  });
  res.cancel = () => source.cancel("Query was cancelled by React Query");
  return res.data;
};

export const fetchCPLinks = async (roles) => {
  if (roles.includes("ROLE_CUSTOMER")) return [];
  const source = CancelToken.source();
  const res = await Axios.get("/api/cp-links/", {
    cancelToken: source.token,
  });
  res.cancel = () => source.cancel("Query was cancelled by React Query");
  return res.data;
};
