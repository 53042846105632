export const EmptySort = {
  customer: null,
  status: null,
  dateFrom: null,
  dateTill: null,
  orderKind: null,
  orderType: null,
  employee: null,
  details: "",
};
