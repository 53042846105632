import {useQuery} from "react-query";
import * as DataService from "../services/customer-service";

export default function usePaymentMethods() {
  return useQuery("paymentMethods", DataService.fetchPaymentMethods, {
    initialData: [],
    initialStale: true,
    keepPreviousData: true,
  });
}
