import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import React from "react";

function InfoMessage({open, onClose, message, type}) {
  if (type === "success") {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={onClose}
      >
        <Alert onClose={onClose} variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
    );
  } else {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={onClose}
      >
        <Alert onClose={onClose} variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
    );
  }
}

export default InfoMessage;
