import {useQuery} from "react-query";
import * as OrderService from ".././services/orders-service";

export default function useOrders(tableID, sortState, customer) {
  return useQuery(
    ["orders", tableID, sortState],
    () => OrderService.sortOrders(tableID, sortState, customer),
    {
      initialData: [],
      initialStale: true,
      keepPreviousData: false,
    }
  );
}
