import Axios from "axios";
import {useMutation, useQueryClient} from "react-query";

export default function useUpdateOrder() {
  let backupOrder = null;
  const queryClient = useQueryClient();
  return useMutation(
    (updateObject) => Axios.put("/api/orders/", updateObject.order),
    {
      onMutate: (mutationObject) => {
        queryClient
          .getQueryCache()
          .findAll(["orders", 1])
          .forEach((query) => {
            queryClient.setQueryData(
              query.queryKey,
              query.state.data.map((cachedOrder) => {
                if (cachedOrder.orderId === mutationObject.order.orderId) {
                  backupOrder = cachedOrder;
                  return mutationObject.order;
                } else return cachedOrder;
              })
            );
          });
      },
      onError: (_error, params) => {
        params.setStatus(true);
        queryClient
          .getQueryCache()
          .findAll(["orders", 1])
          .forEach((query) => {
            queryClient.setQueryData(
              query.queryKey,
              query.state.data.map((cachedOrder) => {
                if (cachedOrder.orderId === backupOrder.orderId) return backupOrder;
                else return cachedOrder;
              })
            );
          });
      },
      onSuccess: (_, updateObject) => {
        queryClient.invalidateQueries(["customers"]);
        queryClient.invalidateQueries(["orders"]);
        updateObject.toggleModal();
      },
    }
  );
}
