import Axios from "axios";
import {SET_UNAUTHENTICATED, RESET_SELECTIONS} from "../context/actions";
import store from "../context/store";

export const authorize = async (credentials) => {
  const response = await Axios.post("/api/auth/signin", credentials);
  const data = response.data;
  return data;
};

export const unauthorize = () => {
  store.dispatch(SET_UNAUTHENTICATED());
  store.dispatch(RESET_SELECTIONS());
};
