import {TextField, withStyles} from "@material-ui/core";
import {useFormikContext} from "formik";
import React from "react";
import {calculateM2, calculateSum} from "./services/utils";

const CustomTextField = withStyles({
  root: {
    "& .MuiInputLabel-marginDense": {
      transform: "translate(14px, 9px) scale(1)",
    },

    "& .MuiOutlinedInput-input": {
      padding: 8,
    },

    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -4px) scale(0.75)",
    },
  },
})(TextField);

const InputField = ({
  label,
  field,
  error,
  readonly = false,
  validator,
  disableCalculation = false,
  numeric = true,
  phone = false,
  ...props
}) => {
  const {
    form: {setFieldValue},
  } = props;
  const {values: form} = useFormikContext();
  const {onChange, name} = field;
  return (
    <CustomTextField
      {...props}
      {...field}
      error={!!error}
      size="small"
      variant="outlined"
      autoComplete="off"
      label={label}
      InputProps={{readOnly: readonly}}
      onChange={(e) => {
        if (!numeric) onChange(e);
        if (e.target.value.match(/^\d*\.?\d*$/) || e.target.value.length === 0) {
          onChange(e);
        }
        validator(name);
      }}
      onKeyUp={(_) => {
        if (!numeric || phone || !form.order || disableCalculation) return;
        calculateM2(form.order);
        const sum = calculateSum(form.order);
        if (sum) setFieldValue("order.finalSum", sum);
      }}
    />
  );
};

export default InputField;
