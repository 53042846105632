import Axios from "axios";
import {useMutation, useQueryClient} from "react-query";

export default function useSaveOrderType() {
  const queryClient = useQueryClient();
  return useMutation((params) => Axios.post("/api/order-types/", params.orderType), {
    onError: (_, params) => {
      params.setStatus({success: false, error: true});
    },
    onSuccess: (_, params) => {
      queryClient.invalidateQueries(["orderTypes"]);
      params.setStatus({success: true, error: false});
    },
  });
}
