import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import {Field, Form, Formik} from "formik";
import React from "react";
import {useQueryClient} from "react-query";
import {useDispatch} from "react-redux";
import * as yup from "yup";
import ComboBox from "./ComboBox";
import {useSelector} from "react-redux";
import {UPDATE_SORTING} from "./context/actions";
import DatePicker from "./DatePicker";
import usePaymentMethods from "./hooks/usePaymentMethods";
import {EmptyFilter} from "./models/FilterObject";
import "./SortForm.css";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    borderRadius: 4,
    backgroundColor: "white",
    margin: "0 auto",
    width: "60%",
  },
  row: {
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
  },
  button: {
    width: "16rem",
    justifySelf: "center",
    gridColumn: 2,
  },
  clear: {
    backgroundColor: "#fff",
    minWidth: 0,
    justifySelf: "flex-end",
    margin: "0px 0px 1px 0",
    width: "2.4rem",
  },
}));

const validationSchema = yup.object({
  sort: yup.object({
    entrepreneur: yup.object().nullable(),
    customer: yup.object().nullable(),
    // status: yup.object().nullable(),
    employee: yup.object().nullable(),
    dateFrom: yup.date().nullable(),
    dateTill: yup.date().nullable(),
    orderKind: yup.object().nullable(),
    orderType: yup.object().nullable(),
    details: yup.string().trim().max(255).nullable(),
    receiver: yup.string().trim().max(255).nullable(),
  }),
});

function SortForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const paymentMethods = usePaymentMethods();
  const sortState = useSelector((state) => state.sortSelections);

  const clearAndUnsort = (setFieldValue) => {
    dispatch(UPDATE_SORTING({...EmptyFilter}, "filter"));
    setFieldValue("sort.customer", null);
    setFieldValue("sort.orderType", null);
    setFieldValue("sort.orderKind", null);
    setFieldValue("sort.dateFrom", null);
    setFieldValue("sort.dateTill", null);
    setFieldValue("sort.payDateFrom", null);
    setFieldValue("sort.payDateTill", null);
    setFieldValue("sort.status", null);
    setFieldValue("sort.employee", null);
    setFieldValue("sort.receiver", "");
    setFieldValue("sort.details", "");
    setFieldValue("showUserOrders", false);
    queryClient.invalidateQueries("customers");
  };

  return (
    <div className={classes.root}>
      <Formik
        validateOnChange={false}
        validateOnMount={false}
        validateOnBlur={false}
        initialValues={{sort: sortState.filter}}
        onSubmit={(form) => dispatch(UPDATE_SORTING(form.sort, "filter"))}
        validationSchema={validationSchema}
      >
        {({values, errors, validateField, setFieldValue}) => (
          <Form>
            <div className={`${classes.row} sort__row__one`}>
              <Field
                name="sort.customer"
                label="Замовник"
                error={errors?.sort?.customer}
                component={ComboBox}
                validator={validateField}
                getName={(option) => option.customerName || ""}
                options={queryClient.getQueryData("customers") || []}
              />
              <Field
                name="sort.entrepreneur"
                label="Підприємець"
                error={errors?.sort?.entrepreneur}
                component={ComboBox}
                validator={validateField}
                getName={(option) => option.fullName || ""}
                options={queryClient.getQueryData("entrepreneurs") || []}
              />
              <Field
                name="sort.orderType"
                label="Вид"
                error={errors?.sort?.orderType}
                component={ComboBox}
                validator={validateField}
                getName={(option) => option.typeName || ""}
                options={queryClient.getQueryData("orderTypes") || []}
              />
              <Field
                name="sort.orderKind"
                label="Тип"
                error={errors?.sort?.orderKind}
                component={ComboBox}
                validator={validateField}
                getName={(option) => option.kindName || ""}
                options={
                  (values.sort.orderType?.typeName === "CTP"
                    ? values.sort.orderType?.orderKinds.sort((a, b) =>
                        parseInt(a.kindName.substring(0, a.kindName.indexOf("х")), 10) >
                        parseInt(b.kindName.substring(0, b.kindName.indexOf("х")), 10)
                          ? 1
                          : -1
                      ) || []
                    : values.sort.orderType?.orderKinds) || []
                }
              />
              <Field
                name="sort.employee"
                label="Оператор"
                error={errors?.sort?.employee}
                component={ComboBox}
                validator={validateField}
                getName={(option) => option.name || ""}
                options={queryClient.getQueryData("employees") || []}
              />
            </div>

            <div className={`${classes.row} sort__row__two`}>
              <Field
                component={DatePicker}
                disabled={false}
                label="Від"
                name="sort.dateFrom"
              />
              <Field component={DatePicker} disabled={false} label="До" name="sort.dateTill" />

              <Field
                component={DatePicker}
                disabled={false}
                label="Оплата від"
                name="sort.payDateFrom"
              />
              <Field
                component={DatePicker}
                disabled={false}
                label="Оплата до"
                name="sort.payDateTill"
              />
              {/* <Field
                name="sort.status"
                label="Статус"
                error={errors?.sort?.status}
                component={ComboBox}
                validator={validateField}
                getName={(option) => option.name || ""}
                options={queryClient.getQueryData("statuses") || []}
              /> */}

              <Field
                name="sort.receiver"
                label="Отримувач"
                error={errors?.sort?.receiver}
                component={ComboBox}
                validator={validateField}
                options={
                  queryClient
                    .getQueryData("employees")
                    ?.map((employee) => employee.name)
                    .concat(paymentMethods?.data.map((method) => method.typeName))
                    .filter(
                      (method) => !["Готівка", "Автоматично", "Роман"].includes(method)
                    ) || []
                }
              />
            </div>

            <div className="sort__row__three">
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                type="submit"
              >
                Сортувати
              </Button>

              <Button
                className={classes.clear}
                onClick={(e) => clearAndUnsort(setFieldValue)}
                variant="contained"
                type="button"
              >
                <RefreshIcon></RefreshIcon>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SortForm;
