import Axios from "axios";
import {useMutation, useQueryClient} from "react-query";
import {useDispatch} from "react-redux";
import {CLOSE_CUSTOMER_MODAL} from "../context/actions";

export default function useSaveCustomer() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (params) =>
      params.customer.customerId === 0
        ? Axios.post("/api/customers/", params.customer)
        : Axios.put("/api/customers/", params.customer),
    {
      onSuccess: (_, passedParams) => {
        queryClient.invalidateQueries("customers");
        dispatch(CLOSE_CUSTOMER_MODAL());
        passedParams.setStatus({success: true, error: false});
      },
      onError: (_, passedParams) => {
        console.log(_);
        passedParams.setStatus({success: false, error: true});
      },
    }
  );
}
