import * as ActionTypes from "./actionTypes";
import {EmptySort} from "../models/SortObject";
import {EmptyFilter} from "../models/FilterObject";

const initialState = {
  active: "main",
  mainTab: 0,
  main: EmptySort,
  sort: EmptySort,
  payment: EmptySort,
  filter: EmptyFilter,
  filterTab: false,
};

function sortReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RESET_SELECTIONS:
      return initialState;
    case ActionTypes.SET_ACTIVE_SORTING:
      return {...state, active: action.payload};
    case ActionTypes.ADD_MAIN_SORTING:
      return {...state, main: {...state.main, ...action.payload}};
    case ActionTypes.ADD_SORT_SORTING:
      return {...state, sort: {...state.sort, ...action.payload}};
    case ActionTypes.ADD_PAYMENT_SORTING:
      return {...state, payment: {...state.payment, ...action.payload}};
    case ActionTypes.ADD_FILTER_SORTING:
      return {...state, filter: {...state.filter, ...action.payload}};
    case ActionTypes.UPDATE_MAIN_SORTING:
      return {...state, main: action.payload};
    case ActionTypes.UPDATE_SORT_SORTING:
      return {...state, sort: action.payload};
    case ActionTypes.UPDATE_PAYMENT_SORTING:
      return {...state, payment: action.payload};
    case ActionTypes.UPDATE_FILTER_SORTING:
      return {...state, filter: action.payload};
    case ActionTypes.SET_MAIN_TAB:
      return {...state, mainTab: action.payload};
    case ActionTypes.SET_FILTER_OPENED:
      return {...state, filterTab: true};
    case ActionTypes.SET_FILTER_CLOSED:
      return {...state, filterTab: false};
    default:
      return state;
  }
}

export default sortReducer;
