import {XGrid} from "@material-ui/x-grid";
import React from "react";
import {useQueryClient} from "react-query";
import "./ClientTable.css";

const formatDate = (date) => {
  if (!date) return "";
  return new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  }).format(date);
};

const columns = [
  {
    field: "name",
    headerName: "Назва замовника",
    flex: 2,
    renderCell: (params) => <b>{params.value}</b>,
  },
  {
    field: "lastOrderDate",
    type: "dateTime",
    headerName: "Останнє замовлення",
    flex: 1,
    valueFormatter: (param) => formatDate(param.value),
  },
  {
    field: "lastPaymentDate",
    type: "string",
    headerName: "Остання оплата",
    flex: 1,
    valueFormatter: (param) => formatDate(param.value),
  },
  {
    field: "debt",
    type: "number",
    headerName: "Борг",
    cellClassName: "centered",
    valueFormatter: (row) => row.value,
    renderCell: (params) => <b>{params.value} грн.</b>,
    flex: 1,
  },
];

const fixDebtors = (debtors) => {
  if (!debtors) return [];
  for (let i = 0; i < debtors.length; i++) {
    debtors[i].id = i;
    debtors[i].lastOrderDate = new Date(debtors[i].lastOrderDate);
    debtors[i].lastPaymentDate = !debtors[i].lastPaymentDate
      ? ""
      : new Date(debtors[i].lastPaymentDate);
  }
  return debtors;
};

export default function ClientsTable() {
  const queryClient = useQueryClient();

  return (
    <div className="x-grid">
      <div className="frame">
        <div className="grid-holder">
          <XGrid
            className="customers-grid"
            rows={fixDebtors(queryClient.getQueryData("debtors")) || []}
            columns={columns}
            loading={queryClient.isFetching("debtors")}
            rowHeight={35}
            headerHeight={35}
            pagination={true}
            disableSelectionOnClick={true}
          />
        </div>
      </div>
    </div>
  );
}
