export const ADD_ORDER = "ADD_ORDER";
export const LOAD_ORDERS = "LOAD_ORDERS";

export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const RESET_SELECTIONS = "RESET_SELECTIONS";

export const SELECT_CUSTOMER = "SELECT_CUSTOMER";
export const UNSELECT_CUSTOMER = "UNSELECT_CUSTOMER";

export const SET_ACTIVE_SORTING = "SET_ACTIVE_SORTING";
export const UPDATE_MAIN_SORTING = "UPDATE_MAIN_SORTING";
export const UPDATE_SORT_SORTING = "UPDATE_SORT_SORTING";
export const UPDATE_PAYMENT_SORTING = "UPDATE_PAYMENT_SORTING";
export const UPDATE_FILTER_SORTING = "UPDATE_FILTER_SORTING";
export const ADD_MAIN_SORTING = "ADD_MAIN_SORTING";
export const ADD_SORT_SORTING = "ADD_SORT_SORTING";
export const ADD_PAYMENT_SORTING = "ADD_PAYMENT_SORTING";
export const ADD_FILTER_SORTING = "ADD_FILTER_SORTING";

export const SHOW_CUSTOMER_MODAL = "SHOW_CUSTOMER_MODAL";
export const CLOSE_CUSTOMER_MODAL = "CLOSE_CUSTOMER_MODAL";

export const SET_MAIN_TAB = "SET_MAIN_TAB";
export const SET_FILTER_OPENED = "SET_FILTER_OPENED";
export const SET_FILTER_CLOSED = "SET_FILTER_CLOSED";
