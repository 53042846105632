import Axios from "axios";
import * as AuthService from "../services/auth-service";
import * as ActionTypes from "./actionTypes";

const initialState = {
  isAuthenticated: false,
  loggedUser: null,
  customer: null,
  roles: [],
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_AUTHENTICATED:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("username", action.payload.username);
      localStorage.setItem("fullName", action.payload.fullName);
      localStorage.setItem("roles", action.payload.roles);

      if (action.payload.customer) {
        localStorage.setItem(
          "customer",
          JSON.stringify({
            customerId: action.payload.customer.customerId,
            customerName: action.payload.customer.customerName,
            money: action.payload.customer.money,
          })
        );
      } else {
        localStorage.setItem("customer", null);
      }

      localStorage.setItem("loggedUser", JSON.stringify(action.payload.employee));

      Axios.defaults.headers.common["Authorization"] = `Bearer ${action.payload.token}`;
      Axios.interceptors.response.use(
        (response) => response,
        (error) => {
          error.response?.status === 401 ? AuthService.unauthorize() : console.error(error);
          throw error;
        }
      );
      return {
        isAuthenticated: true,
        loggedUser: action.payload.employee,
        customer: action.payload.customer,
        roles: action.payload.roles,
      };
    case ActionTypes.SET_UNAUTHENTICATED:
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("fullName");
      localStorage.removeItem("roles");
      localStorage.removeItem("customer");
      localStorage.removeItem("loggedUser");
      return initialState;
    default:
      return state;
  }
}

export default authReducer;
