import authReducer from "./auth-reducer";
import sortReducer from "./sort-reducer";
import customerModalReducer from "./customerModal-reducer";

const {createStore, combineReducers} = require("redux");
const {default: orderReducer} = require("./order-reducer");

const store = createStore(
  combineReducers({
    auth: authReducer,
    orders: orderReducer,
    sortSelections: sortReducer,
    showCustomerModal: customerModalReducer,
  })
);

export default store;
