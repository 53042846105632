export const EmptyOrder = {
  orderId: 0,
  billNo: 0,
  date: "",
  employee: {userID: 60},
  entrepreneur: null,
  customer: null,
  orderType: null,
  orderKind: null,
  width: 0,
  height: 0,
  amount: 0,
  m2: 0.0,
  price: 0,
  fees: 0,
  finalSum: 0,
  paySum: 0,
  status: {id: 1},
  comment: "",
};
