import {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ActionBar from "./ActionBar";
import SortActionBar from "./SortActionBar";
import UsersActionBar from "./UsersActionBar";
import "./App.css";
import ClientsTable from "./ClientsTable";
import DebtorsTable from "./DebtorsTable";
import CustomerForm from "./CustomerForm";
import Header from "./Header";
import useCELinks from "./hooks/useCELinks";
import useCPLinks from "./hooks/useCPLinks";
import useCustomers from "./hooks/useCustomers";
import useEmployees from "./hooks/useEmployees";
import useUsers from "./hooks/useUsers";
import useEntrepreneurs from "./hooks/useEntrepreneurs";
import useOrderTypes from "./hooks/useOrderTypes";
import useStatuses from "./hooks/useStatuses";
import useDebtors from "./hooks/useDebtors";
import Login from "./Login";
import Table from "./Table";

function App() {
  const [tableHeight, setTableHeight] = useState(0);

  useCustomers();
  useEntrepreneurs();
  useEmployees();
  useOrderTypes();
  useStatuses();
  useUsers();
  useCELinks();
  useCPLinks();
  useDebtors();

  useEffect(() => {
    if (!tableHeight) {
      if (document.getElementsByClassName("main__header")[0]) {
        setTableHeight(
          window.innerHeight -
            (32 +
              document.getElementsByClassName("action__bar__button")[0]?.clientHeight +
              document.getElementsByClassName("main__header")[0]?.clientHeight)
        );
      } else {
        setTableHeight(
          window.innerHeight -
            (32 +
              document.getElementsByClassName("action__bar__button")[0]?.clientHeight +
              document.getElementsByClassName("sort__header")[0]?.clientHeight)
        );
      }
    }
  }, [tableHeight]);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route exact path="/">
            <div className="main">
              <div className="main__header">
                <Header radius={0} />
                <ActionBar />
              </div>
              <div
                className="main__table"
                style={{
                  height: tableHeight,
                }}
              >
                <Table tableID={1} key={1} />
              </div>
            </div>
          </Route>
          <Route path="/clients">
            <div className="clients">
              <div className="clients__header">
                <Header radius={4} />
              </div>
              <div
                className="clients__table"
                style={{
                  height:
                    window.innerHeight -
                    (62 + document.getElementsByClassName("clients__header")[0]?.clientHeight),
                }}
              >
                <ClientsTable />
              </div>
            </div>
          </Route>
          <Route path="/sort">
            <div className="sort">
              <div className="sort__header">
                <Header radius={0} />
                <SortActionBar />
              </div>
              <div
                className="sort__table"
                style={{
                  height: tableHeight,
                }}
              >
                <Table tableID={2} key={2} />
              </div>
            </div>
          </Route>
          <Route path="/users">
            <div className="users">
              <div className="users__header">
                <Header radius={0} />
                <UsersActionBar />
              </div>
            </div>
          </Route>
          <Route path="/debtors">
            <div className="debtors">
              <div className="debtors__header">
                <Header radius={4} />
              </div>
              <div
                className="debtors__table"
                style={{
                  height:
                    window.innerHeight -
                    (32 + document.getElementsByClassName("debtors__header")[0]?.clientHeight),
                }}
              >
                <DebtorsTable />
              </div>
            </div>
          </Route>
        </Switch>
      </Router>
      <CustomerForm />
    </div>
  );
}

export default App;
