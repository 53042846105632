import {TextField, withStyles} from "@material-ui/core";
import {useFormikContext} from "formik";
import React from "react";

const CustomTextField = withStyles({
  root: {
    "& .MuiInputLabel-marginDense": {
      transform: "translate(14px, 9px) scale(1)",
    },

    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -4px) scale(0.75)",
    },
  },
})(TextField);

const DependentInput = ({name, label, field, error, validator, value, ...props}) => {
  const {onChange} = field;
  const {
    values: {payment},
    setFieldValue,
  } = useFormikContext();

  React.useEffect(() => {
    if (payment.paymentType) {
      setFieldValue("payment.percentage", payment.paymentType?.paymentPercentage);
    } else {
      setFieldValue("payment.percentage", 0);
    }
  }, [payment.paymentType, setFieldValue]);

  return (
    <CustomTextField
      {...props}
      {...field}
      error={!!error}
      id="metrics"
      size="small"
      variant="outlined"
      autoComplete="off"
      label={label}
      onChange={(e) => {
        if (e.target.value.match(/^\d*\.?\d*$/) || e.target.value.length === 0) {
          setFieldValue("payment.percentage", e.target.value);
          onChange(e);
        }
        validator(name);
      }}
    />
  );
};

export default DependentInput;
