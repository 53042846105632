import Axios from "axios";
import {useMutation} from "react-query";

export default function useExportOrders() {
  return useMutation(
    (form) => {
      form.setBackdrop(true);
      return Axios.post(
        "api/orders/export/",
        {orders: form.orders},
        {
          responseType: "arraybuffer",
        }
      );
    },
    {
      onError: (_, params) => {
        params.setStatus({success: false, error: true});
        params.setBackdrop(false);
      },
      onSuccess: (val, params) => {
        const blob = new Blob([val.data], {
          type: "application/zip",
        });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = data;
        link.download = "Список замовлень.zip";
        link.dispatchEvent(
          new MouseEvent("click", {bubbles: true, cancelable: true, view: window})
        );
        params.setBackdrop(false);
      },
    }
  );
}
