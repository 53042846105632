import {Redirect, Route} from "react-router-dom";
import {SET_AUTHENTICATED} from "./context/actions";
import store from "./context/store";
import {useSelector} from "react-redux";

export const ProtectedRoute = ({component: Component, ...props}) => {
  const authState = useSelector((store) => store.auth);
  const authToken = localStorage.getItem("token");
  const username = localStorage.getItem("username");
  const fullName = localStorage.getItem("fullName");
  const roles = localStorage.getItem("roles")?.split(",") || [];
  const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
  const customer = JSON.parse(localStorage.getItem("customer"));

  return (
    <Route
      {...props}
      render={(props) => {
        if (authState.isAuthenticated) {
          return <Component {...props} />;
        } else if (authToken) {
          store.dispatch(
            SET_AUTHENTICATED(authToken, loggedUser, customer, username, roles, fullName)
          );
          return <Component {...props} />;
        } else {
          return <Redirect to={{pathname: "/login", state: {from: props.location}}} />;
        }
      }}
    />
  );
};
