export const CustomerObject = {
  customerId: 0,
  customerName: "",
  customerGroup: "",
  money: 0,
  payLog: "",
  debtor: false,
  city: "",
  info: "",
  firstPerson: "",
  firstPhone: "",
  firstEmail: "",
  secondPerson: "",
  secondPhone: "",
  secondEmail: "",
  thirdPerson: "",
  thirdPhone: "",
  thirdEmail: "",
};
