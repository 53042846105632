import Axios from "axios";
import {useMutation, useQueryClient} from "react-query";

export default function useSaveCELink() {
  const queryClient = useQueryClient();
  return useMutation(
    (params) =>
      Axios.get("/api/ce-links/" + params.customerId + "/" + params.entrepreneurId),
    {
      onSuccess: (_, passedParams) => {
        queryClient.invalidateQueries("ce-links");
        passedParams.setStatus({success: true, error: false});
      },
      onError: (_, passedParams) => {
        passedParams.setStatus({success: false, error: true});
      },
    }
  );
}
