import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {LinkOutlined} from "@material-ui/icons";
import {Field, Form, Formik} from "formik";
import React from "react";
import {useQueryClient} from "react-query";
import * as yup from "yup";
import ComboBox from "./ComboBox";
import {UPDATE_SORTING} from "./context/actions";
import store from "./context/store";
import {useSelector} from "react-redux";
import DatePicker from "./DatePicker";
import DependentInput from "./DependentInput";
import useMakePayment from "./hooks/useMakePayment";
import usePaymentMethods from "./hooks/usePaymentMethods";
import useUpdatePaymentMethod from "./hooks/useUpdatePaymentMethod";
import InfoMessage from "./InfoMessage";
import InputField from "./InputField";
import RefreshIcon from "@material-ui/icons/Refresh";
import {EmptySort} from "./models/SortObject";
import {setCurrentTime} from "./services/utils";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    borderRadius: 4,
    backgroundColor: "white",
    margin: "0 auto",
    width: "52%",
  },
  row__one: {
    display: "grid",
    gridColumnGap: "20px",
    gridTemplateColumns: "2fr 1fr",
    margin: ".5rem auto 1rem auto",
  },
  row__two: {
    display: "grid",
    gridColumnGap: "20px",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr 0.05fr 1fr 1fr",
    margin: "1rem auto",

    "& .MuiOutlinedInput-input": {
      padding: "8px 8px",
    },
  },
  button: {
    gridColumn: 2,
    width: "16rem",
    height: "2.375rem",
    justifySelf: "center",
  },
  clear: {
    backgroundColor: "#fff",
    minWidth: 0,
    justifySelf: "flex-end",
    margin: "0px 0px 1px 0",
    width: "2.4rem",
  },
  lock: {
    padding: 0,
    width: "100%",
    backgroundColor: "white !important",
    minWidth: 0,

    "&:hover": {
      backgroundColor: "white",
    },
  },
  svg: {
    fill: "rgba(0, 0, 0, 0.23)",

    "&:hover": {
      fill: "black",
    },
  },
  successInfo: {
    display: "flex",
  },
  successText: {
    margin: "auto .5rem",
    color: "#27A637",
  },
  errorInfo: {
    display: "flex",
  },
  errorText: {
    margin: "auto .5rem",
    color: "#d2000a",
  },
  errorSvg: {
    fontSize: "2.54rem",
    fill: "#d2000a",
  },
  successSvg: {
    fontSize: "2.54rem",
    fill: "#27A637",
  },
}));

const validationSchema = yup.object({
  payment: yup.object({
    entrepreneur: yup.object().nullable(),
    customer: yup.object().required(),
    paymentDate: yup.date().required(),
    sum: yup.number().lessThan(2000000000).positive().required(),
    percentage: yup.number().lessThan(2000000000).positive().min(0).required(),
    paymentType: yup.object().required(),
  }),
});

function PaymentForm() {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const makePaymentQuery = useMakePayment();
  const paymentMethods = usePaymentMethods();
  const sortState = useSelector((state) => state.sortSelections);
  const updatePaymentMethodQuery = useUpdatePaymentMethod();
  const [showPaymentInfo, setShowPaymentInfo] = React.useState({
    success: false,
    error: false,
  });
  const [showPercentageLinkInfo, setShowPercentageLinkInfo] = React.useState({
    success: false,
    error: false,
  });

  const handleClose = () => {
    setShowPaymentInfo({success: false, error: false});
    setShowPercentageLinkInfo({success: false, error: false});
  };

  const linkPercentage = (paymentMethod, percentage) => {
    paymentMethod.paymentPercentage = percentage;
    updatePaymentMethodQuery.mutate({
      paymentMethod: paymentMethod,
      setStatus: setShowPercentageLinkInfo,
    });
  };

  const clearAndUnsort = (setFieldValue) => {
    queryClient.invalidateQueries("customers");
    store.dispatch(UPDATE_SORTING({...EmptySort}, "payment"));
    setFieldValue("payment.customer", null);
    setFieldValue("payment.entrepreneur", null);
    setFieldValue("payment.paymentDate", new Date());
    setFieldValue("payment.paymentType", null);
    setFieldValue("payment.percentage", 0);
    setFieldValue("payment.sum", null);
  };

  return (
    <div className={classes.root}>
      <Formik
        validateOnChange={false}
        validateOnMount={false}
        validateOnBlur={false}
        initialValues={{payment: {...sortState.payment, paymentDate: new Date()}}}
        validationSchema={validationSchema}
        onSubmit={(form) => {
          setCurrentTime(form.payment.paymentDate);
          form.payment.receiver = form.payment.paymentType.typeName;
          makePaymentQuery.mutate({
            payment: form.payment,
            setStatus: setShowPaymentInfo,
          });
        }}
      >
        {({values, errors, validateField, setFieldValue}) => (
          <Form style={{margin: "0 auto"}}>
            <div className={classes.row__one}>
              <Field
                name="payment.customer"
                label="Замовник"
                error={errors?.payment?.customer}
                component={ComboBox}
                validator={validateField}
                sortKey="customer"
                sortPage="payment"
                disabled={makePaymentQuery.isLoading}
                getName={(option) => option.customerName || ""}
                options={queryClient.getQueryData("customers") || []}
              />
              <Field
                name="payment.entrepreneur"
                label="Підприємець"
                error={errors?.payment?.entrepreneur}
                validator={validateField}
                component={ComboBox}
                sortKey="entrepreneur"
                sortPage="payment"
                disabled={makePaymentQuery.isLoading}
                getName={(option) => option.fullName || ""}
                options={queryClient.getQueryData("entrepreneurs") || []}
              />
            </div>

            <div className={classes.row__two}>
              <Field
                component={DatePicker}
                label="Дата"
                name="payment.paymentDate"
                validator={validateField}
                disabled={makePaymentQuery.isLoading}
              />

              <Field
                name="payment.paymentType"
                label="Тип оплати"
                error={errors?.payment?.paymentType}
                component={ComboBox}
                validator={validateField}
                disabled={makePaymentQuery.isLoading}
                getName={(option) => option.typeName || ""}
                options={paymentMethods?.data || []}
              />
              <Button
                className={classes.lock}
                disableRipple={true}
                onClick={() =>
                  linkPercentage(values.payment.paymentType, values.payment.percentage)
                }
              >
                <LinkOutlined className={classes.svg} />
              </Button>
              <Field
                name="payment.percentage"
                label="Відсоток"
                error={errors?.payment?.percentage}
                validator={validateField}
                component={DependentInput}
                disabled={makePaymentQuery.isLoading}
              />
              <Field
                name="payment.sum"
                label="Сума"
                error={errors?.payment?.sum}
                validator={validateField}
                component={InputField}
                disabled={makePaymentQuery.isLoading}
              />
            </div>

            <div className="sort__row__three">
              <Button
                color="primary"
                variant="contained"
                disabled={makePaymentQuery.isLoading}
                className={classes.button}
                type="submit"
              >
                Оплатити
              </Button>

              <Button
                className={classes.clear}
                onClick={(e) => clearAndUnsort(setFieldValue)}
                disabled={makePaymentQuery.isLoading}
                variant="contained"
                type="button"
              >
                <RefreshIcon></RefreshIcon>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <InfoMessage
        type="success"
        open={showPaymentInfo.success}
        onClose={handleClose}
        message="Оплата успішно внесена."
      />
      <InfoMessage
        type="error"
        open={showPaymentInfo.error}
        onClose={handleClose}
        message="Помилка при внесенні оплати."
      />
      <InfoMessage
        type="success"
        open={showPercentageLinkInfo.success}
        onClose={handleClose}
        message="Зв'язок успішно збережений."
      />
      <InfoMessage
        type="error"
        open={showPercentageLinkInfo.error}
        onClose={handleClose}
        message="Помилка при збереженні зв'язку."
      />
    </div>
  );
}

export default PaymentForm;
