import Axios from "axios";
import {useMutation, useQueryClient} from "react-query";
import {UPDATE_SORTING} from "../context/actions";
import store from "../context/store";
import {EmptySort} from "../models/SortObject";

export default function useSaveOrder() {
  const queryClient = useQueryClient();
  return useMutation((params) => Axios.post("/api/orders/", params.newOrder), {
    onSuccess: (_, passedParams) => {
      passedParams.setStatus({success: true, error: false});
      queryClient.invalidateQueries(["customers"]);
      if (passedParams.isReset) {
        store.dispatch(UPDATE_SORTING({...EmptySort}, "main"));
      } else {
        queryClient.invalidateQueries(["orders"]);
      }
      passedParams.resetForm();
    },
    onError: (_, passedParams) => {
      passedParams.setStatus({success: false, error: true});
    },
  });
}
