import {Button, Collapse} from "@material-ui/core";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import "./ActionBar.css";
import {SET_ACTIVE_SORTING, SET_FILTER_OPENED, SET_FILTER_CLOSED} from "./context/actions";
import FilterForm from "./FilterForm";

export default function SortActionBar() {
  const dispatch = useDispatch();
  const sortState = useSelector((state) => state.sortSelections);

  const toggle = () =>
    sortState.filterTab ? dispatch(SET_FILTER_CLOSED()) : dispatch(SET_FILTER_OPENED());

  const setSortView = (type) => {
    if (sortState.active === type) return;
    dispatch(SET_ACTIVE_SORTING(type));
  };

  return (
    <div>
      <div className="action_bar_container">
        <div className="action__bar">
          <div className="action__bar__info"></div>
          <div className="action__bar__button">
            <Button
              style={{marginLeft: ".5rem"}}
              variant="contained"
              color={sortState.filterTab ? "" : "primary"}
              onClick={toggle}
            >
              Сортування
            </Button>
          </div>
        </div>

        <Collapse
          in={sortState.filterTab}
          timeout={300}
          onEntered={() => setSortView("filter")}
          unmountOnExit
        >
          <FilterForm />
        </Collapse>
      </div>
    </div>
  );
}
