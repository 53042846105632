import Axios from "axios";
import {useMutation, useQueryClient} from "react-query";

export default function useSaveCELink() {
  const queryClient = useQueryClient();
  return useMutation(
    (params) =>
      Axios.get(
        "/api/cp-links/" +
          params.customerId +
          "/" +
          params.orderKindId +
          "/" +
          params.price
      ),
    {
      onSuccess: (_, passedParams) => {
        queryClient.invalidateQueries("cp-links");
        passedParams.setStatus({success: true, error: false});
      },
      onError: (_, passedParams) => {
        passedParams.setStatus({success: false, error: true});
      },
    }
  );
}
