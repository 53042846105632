import Axios from "axios";
import {useMutation, useQueryClient} from "react-query";

export default function useDeleteOrder() {
  const queryClient = useQueryClient();
  return useMutation((params) => Axios.delete("/api/orders/" + params.orderId), {
    onError: (_, params) => {
      params.setStatus(true);
    },
    onSuccess: (_, params) => {
      queryClient.invalidateQueries(["customers"]);
      queryClient.invalidateQueries(["orders"]);
      params.setShowModal(false);
    },
  });
}
