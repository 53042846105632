import Axios from "axios";
import {useMutation, useQueryClient} from "react-query";

export default function useSetStatus() {
  const queryClient = useQueryClient();
  return useMutation(
    (params) => Axios.get("/api/statuses/" + params.orderId + "/" + params.statusId),
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries("orders");
        params.toggle();
      },
    }
  );
}
