import {TextField, withStyles} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useFormikContext} from "formik";
import {fieldToTextField} from "formik-material-ui";
import React from "react";
import {useQueryClient} from "react-query";
import {useDispatch} from "react-redux";
import {ADD_SORTING_BY} from "./context/actions";
import {calculateSum} from "./services/utils";

const NoPaddingAutocomplete = withStyles({
  root: {
    "& .MuiInputLabel-marginDense": {
      transform: "translate(14px, 9px) scale(1)",
    },

    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -4px) scale(0.75)",
    },

    "& .MuiAutocomplete-endAdornment": {
      top: "inherit",
    },

    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-endAdornment": {
      right: 5,
    },
  },
  inputRoot: {
    '&&[class*="MuiOutlinedInput-root"] $input': {
      padding: 2,
    },
    '&&[class*="MuiOutlinedInput-root"]': {
      paddingRight: "40px!important",
    },
  },
  input: {},
})(Autocomplete);

function ComboBox({
  label,
  getName,
  sortKey,
  error,
  validator,
  kindNameSetter = false,
  typeNameSetter = false,
  setEntrepreneur = false,
  setDimensions = false,
  setPrice = false,
  action = false,
  sortPage = "",
  textFieldProps,
  ...props
}) {
  const {
    form: {setFieldValue},
  } = props;
  const {
    values: {order},
  } = useFormikContext();
  const {...field} = fieldToTextField(props);
  const {name} = field;
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return (
    <NoPaddingAutocomplete
      {...props}
      size="small"
      error={""}
      value={field.value}
      autoHighlight={true}
      getOptionLabel={getName}
      onKeyPress={(e) => {
        if (e.key === "=" || e.key === "+") {
          e.preventDefault();
          if (action) action();
        }
      }}
      onChange={(_, value) => {
        if (sortKey) {
          const sortBy = {};
          sortBy[sortKey] = value;
          dispatch(ADD_SORTING_BY(sortBy, sortPage));
        }

        if (setEntrepreneur) {
          const entrepreneur =
            queryClient
              .getQueryData("ce-links")
              .find((link) => link.customer.customerId === value?.customerId)?.entrepreneur ||
            null;
          setFieldValue("order.entrepreneur", entrepreneur);
        }

        setFieldValue(name, value);

        if (setPrice) {
          if (!value) return;
          let customerId;
          let orderKindId;
          let width = order.width;
          let height = order.height;

          if (value.customerId) {
            customerId = value.customerId;
            orderKindId = order.orderKind?.kindId;
          } else {
            customerId = order.customer?.customerId;
            orderKindId = value.kindId;
            width = value.width || width;
            height = value.height || height;
          }
          if (!customerId || !orderKindId) return;
          const price =
            queryClient
              .getQueryData("cp-links")
              .find(
                (link) =>
                  link.customer?.customerId === customerId &&
                  link.orderKind?.kindId === orderKindId
              )?.price || 0;
          setFieldValue("order.price", price);

          const sum = calculateSum({
            ...order,
            price: price,
            width: width,
            height: height,
          });
          if (sum) setFieldValue("order.finalSum", sum);
        }

        if (setDimensions && value && value.width && value.height) {
          setFieldValue("order.width", value.width);
          setFieldValue("order.height", value.height);
        }
        validator(name);
      }}
      renderInput={(props) => (
        <TextField
          {...props}
          error={!!error}
          onChange={(e) => {
            if (typeNameSetter) {
              typeNameSetter(e.target.value);
            } else if (kindNameSetter) {
              kindNameSetter(e.target.value);
            }
          }}
          label={label}
          variant="outlined"
        />
      )}
    />
  );
}

export default ComboBox;
