import Axios from "axios";
import {useMutation, useQueryClient} from "react-query";

export default function useUpdatePaymentMethod() {
  const queryClient = useQueryClient();
  return useMutation(
    (params) => Axios.put("/api/payments/types/", params.paymentMethod),
    {
      onSuccess: (_, passedParams) => {
        queryClient.invalidateQueries("paymentMethods");
        passedParams.setStatus({success: true, error: false});
      },
      onError: (_, passedParams) => {
        passedParams.setStatus({success: false, error: true});
      },
    }
  );
}
