import Axios from "axios";
import {useMutation, useQueryClient} from "react-query";

export default function useAssignCustomer() {
  const queryClient = useQueryClient();
  return useMutation(
    (params) =>
      Axios.get("/api/users/" + params.form.user.id + "/" + params.form.customer.customerId),
    {
      onSuccess: (_, passedParams) => {
        queryClient.invalidateQueries("users");
        passedParams.resetForm();
        passedParams.setStatus({success: true, error: false});
      },
      onError: (_, passedParams) => {
        passedParams.setStatus({success: false, error: true});
      },
    }
  );
}
