import * as ActionTypes from "./actionTypes";

const initialState = {
  mainPageOrderList: [],
  sortPageOrderList: [],
  isLoading: true,
};

function orderReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADD_ORDER:
      return {
        ...state,
        mainPageOrderList: [...state.mainPageOrderList, action.payload],
        sortPageOrderList: [...state.sortPageOrderList, action.payload],
      };
    case ActionTypes.LOAD_ORDERS:
      if (!action.payload) return state;
      return {
        ...state,
        mainPageOrderList: action.payload,
        sortPageOrderList: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default orderReducer;
