import {useQuery} from "react-query";
import {useSelector} from "react-redux";
import * as CustomerService from ".././services/customer-service";

export default function useEmployees() {
  const authState = useSelector((state) => state.auth);
  return useQuery("employees", () => CustomerService.fetchEmployees(authState.roles), {
    refetchOnWindowFocus: false,
  });
}
