import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import {Field, Form, Formik} from "formik";
import React, {useEffect} from "react";
import {useQueryClient} from "react-query";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import ComboBox from "./ComboBox";
import {SET_ACTIVE_SORTING, UPDATE_SORTING} from "./context/actions";
import DatePicker from "./DatePicker";
import InputField from "./InputField";
import {EmptySort} from "./models/SortObject";
import "./SortForm.css";
import SwitchControl from "./SwitchControl";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    borderRadius: 4,
    backgroundColor: "white",
    margin: "0 auto",
    width: "52%",
  },
  button: {
    width: "16rem",
    justifySelf: "center",
  },
  clear: {
    backgroundColor: "#fff",
    minWidth: 0,
    justifySelf: "flex-end",
    margin: "0px 0px 1px 0",
    width: "2.4rem",
  },
}));

const validationSchema = yup.object({
  sort: yup.object({
    customer: yup.object().nullable(),
    status: yup.object().nullable(),
    dateFrom: yup.date().nullable(),
    dateTill: yup.date().nullable(),
    orderKind: yup.object().nullable(),
    orderType: yup.object().nullable(),
    details: yup.string().trim().max(255).nullable(),
  }),
});

function SortForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const authState = useSelector((state) => state.auth);
  const sortState = useSelector((state) => state.sortSelections);

  useEffect(() => {
    if (sortState.active === "sort") return;
    dispatch(SET_ACTIVE_SORTING("sort"));
    // eslint-disable-next-line
  }, []);

  const clearAndUnsort = (setFieldValue) => {
    dispatch(UPDATE_SORTING({...EmptySort}, "sort"));
    setFieldValue("sort.customer", null);
    setFieldValue("sort.orderType", null);
    setFieldValue("sort.orderKind", null);
    setFieldValue("sort.dateFrom", null);
    setFieldValue("sort.dateTill", null);
    setFieldValue("sort.status", null);
    setFieldValue("sort.details", "");
    setFieldValue("showUserOrders", false);
    queryClient.invalidateQueries("customers");
  };

  return (
    <div className={classes.root}>
      <Formik
        validateOnChange={false}
        validateOnMount={false}
        validateOnBlur={false}
        initialValues={{sort: sortState.sort, showUserOrders: false}}
        onSubmit={(form) => {
          form.showUserOrders
            ? (form.sort.employee = authState.loggedUser)
            : (form.sort.employee = null);
          form.sort.details = form.sort.details.trim();
          dispatch(UPDATE_SORTING(form.sort, "sort"));
        }}
        validationSchema={validationSchema}
      >
        {({values, errors, validateField, setFieldValue}) => (
          <Form>
            <div className="sort__row__one">
              <Field
                name="sort.customer"
                label="Замовник"
                error={errors?.sort?.customer}
                component={ComboBox}
                validator={validateField}
                getName={(option) => option.customerName || ""}
                options={queryClient.getQueryData("customers") || []}
              />
              <Field
                name="sort.orderType"
                label="Вид"
                error={errors?.sort?.orderType}
                component={ComboBox}
                validator={validateField}
                getName={(option) => option.typeName || ""}
                options={queryClient.getQueryData("orderTypes") || []}
              />
              <Field
                name="sort.orderKind"
                label="Тип"
                error={errors?.sort?.orderKind}
                component={ComboBox}
                validator={validateField}
                getName={(option) => option.kindName || ""}
                options={
                  (values.sort?.orderType?.typeName === "CTP"
                    ? values.sort?.orderType?.orderKinds.sort((a, b) =>
                        parseInt(a.kindName.substring(0, a.kindName.indexOf("х")), 10) >
                        parseInt(b.kindName.substring(0, b.kindName.indexOf("х")), 10)
                          ? 1
                          : -1
                      ) || []
                    : values.sort?.orderType?.orderKinds) || []
                }
              />
            </div>

            <div className="sort__row__two">
              <div className="datepickers">
                <Field
                  component={DatePicker}
                  disabled={false}
                  label="Від"
                  name="sort.dateFrom"
                />
                <Field
                  component={DatePicker}
                  disabled={false}
                  label="До"
                  name="sort.dateTill"
                />
              </div>
              <Field
                name="sort.status"
                label="Статус"
                error={errors?.sort?.status}
                component={ComboBox}
                validator={validateField}
                getName={(option) => option.name || ""}
                options={queryClient.getQueryData("statuses") || []}
              />
              <Field
                name="sort.details"
                label="Опис замовлення"
                error={errors?.sort?.details}
                numeric={false}
                component={InputField}
                validator={validateField}
              />
            </div>

            <div className="sort__row__three">
              <SwitchControl
                name="showUserOrders"
                isChecked={values.showUserOrders}
                label="Лише мої замовлення"
              />

              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                type="submit"
              >
                Сортувати
              </Button>

              <Button
                className={classes.clear}
                onClick={(e) => clearAndUnsort(setFieldValue)}
                variant="contained"
                type="button"
              >
                <RefreshIcon></RefreshIcon>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SortForm;
