import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import Link from "@material-ui/core/Link";
import "./NotFound.css";

function NotFound() {
  return (
    <Paper elevation={3} className="blue-holder">
      <Paper elevation={3} className="info">
        <div className="blank-page">
          <Typography variant="h4" component="h3">
            Сторінка не знайдена
          </Typography>{" "}
          <Typography variant="h2" component="h3">
            404
          </Typography>
        </div>
        <Box mt={8}>
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://psdruk.com.ua/">
              psdruk.com.ua
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Paper>
    </Paper>
  );
}

export default NotFound;
