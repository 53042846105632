import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Field, Form, Formik} from "formik";
import React from "react";
import {useQueryClient} from "react-query";
import * as yup from "yup";
import "./ActionBar.css";
import ComboBox from "./ComboBox";
import InfoMessage from "./InfoMessage";
import useAssignCustomer from "./hooks/useAssignCustomer";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "white",
    padding: 20,
  },
  row: {
    display: "grid",
    width: "45%",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    gap: "10px 20px",
    margin: "0 auto",
  },
  submit: {
    width: "30%",
    gridColumn: "1/3",
    margin: "0 auto",
  },
}));

const validationSchema = yup.object({
  user: yup.object().nullable(),
  customer: yup.object().nullable(),
});

export default function SortActionBar() {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const assignCustomer = useAssignCustomer();
  const [showAssignmentResult, setShowAssignmentResult] = React.useState({
    success: false,
    error: false,
  });

  const handleClose = () => {
    setShowAssignmentResult({success: false, error: false});
  };

  return (
    <div className={classes.root}>
      <Formik
        validateOnChange={false}
        validateOnMount={false}
        validateOnBlur={false}
        initialValues={{user: null, customer: null}}
        onSubmit={(form, actions) =>
          assignCustomer.mutate({
            form: form,
            setStatus: setShowAssignmentResult,
            resetForm: actions.resetForm,
          })
        }
        validationSchema={validationSchema}
      >
        {({errors, validateField}) => (
          <Form>
            <div className={classes.row}>
              <Field
                name="user"
                label="Користувач"
                error={errors?.user}
                component={ComboBox}
                validator={validateField}
                getName={(option) => option.fullName || ""}
                options={queryClient.getQueryData("users") || []}
              />
              <Field
                name="customer"
                label="Замовник"
                error={errors?.customer}
                component={ComboBox}
                validator={validateField}
                getName={(option) => option.customerName || ""}
                options={queryClient.getQueryData("customers") || []}
              />

              <Button
                className={classes.submit}
                color="primary"
                variant="contained"
                type="submit"
              >
                Зберегти
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <InfoMessage
        type="success"
        open={showAssignmentResult.success}
        onClose={handleClose}
        message="Зв'язок успішно збережений."
      />
      <InfoMessage
        type="error"
        open={showAssignmentResult.error}
        onClose={handleClose}
        message="Помилка при збереженні зв'язку."
      />
    </div>
  );
}
