import {useQuery} from "react-query";
import {useSelector} from "react-redux";
import * as CustomerService from ".././services/customer-service";

export default function useOrderTypes() {
  const authState = useSelector((state) => state.auth);
  return useQuery("orderTypes", () => CustomerService.fetchOrderTypes(authState.roles), {
    initialData: [],
    initialStale: true,
    keepPreviousData: true,
  });
}
