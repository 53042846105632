import {useQuery} from "react-query";
import {useSelector} from "react-redux";
import * as CustomerService from ".././services/customer-service";

export default function useUsers() {
  const authState = useSelector((state) => state.auth);
  return useQuery("users", () => CustomerService.fetchUsers(authState.roles), {
    refetchOnWindowFocus: false,
  });
}
