export function calculateM2(order) {
  order.m2 = (((order.width * order.height) / 1000000) * order.amount).toFixed(3) || 0.0;
}

export const checkPriceShrink = (form, shrinkStatus, setShrinkStatus) => {
  if (form.price >= 0) {
    if (!shrinkStatus.shrink) {
      setShrinkStatus({
        shrink: true,
      });
    }
  } else if (shrinkStatus.shrink) {
    setShrinkStatus({
      shrink: false,
    });
  }
};

export const fixCustomers = (data, id = 0) => {
  return data.map((row) => {
    if (row.orderId === 0) {
      row.id = id;
      row.customerId = id;
    } else {
      row.id = row.customerId;
    }
    return row;
  });
};

export const setCurrentTime = (date) => {
  const currentTime = new Date();
  date.setHours(currentTime.getHours());
  date.setMinutes(currentTime.getMinutes());
  date.setSeconds(currentTime.getSeconds());
};

export const calculateSum = (order) => {
  calculateM2(order);
  return order.m2 > 0
    ? Math.ceil(+(order.m2 * order.price) + +order.fees)
    : Math.ceil(order.price * order.amount + +order.fees);
};
