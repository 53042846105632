import Axios from "axios";

export const fixOrders = (data, id = 0) => {
  return data.map((row) => {
    if (row.orderId === 0) {
      row.id = id;
      row.orderId = id;
    } else {
      row.id = row.orderId;
    }
    !row.date ? (row.date = new Date()) : (row.date = new Date(row.date));
    return row;
  });
};

export const fetchRecentOrders = async () => {
  const response = await Axios.get("/api/orders/");
  const data = response.data;

  if (data) return fixOrders(data);
  return [];
};

export const sortOrders = async (tableId, selections, customer) => {
  const form = {
    orderId: null,
    customer: null,
    entrepreneur: null,
    employee: null,
    status: null,
    dateFrom: null,
    dateTill: null,
    payDateFrom: null,
    payDateTill: null,
    receiver: null,
    orderKind: null,
    orderType: null,
    details: "",
  };

  const sendForm = {
    ...form,
    orderId: selections?.orderId,
    customer: selections?.customer,
    entrepreneur: selections?.entrepreneur,
    employee: selections?.employee,
    status: selections?.status,
    dateFrom: selections?.dateFrom,
    dateTill: selections?.dateTill,
    payDateFrom: selections?.payDateFrom,
    payDateTill: selections?.payDateTill,
    receiver: selections?.receiver,
    orderKind: selections?.orderKind,
    orderType: selections?.orderType,
    details: selections?.details,
  };

  let response;
  if (customer) {
    response = await Axios.get("/api/orders/" + customer.customerId);
  } else {
    if (tableId === 1) {
      response = await Axios.post("/api/orders/filter/", sendForm);
    } else if (tableId === 2) {
      response = await Axios.post("/api/orders/filter?stat=true", sendForm);
    }
  }

  const data = response.data;

  if (data && tableId === 2) {
    return {...data, listOfOrders: fixOrders(data.listOfOrders)};
  }

  if (data) return fixOrders(data);
  return [];
};
