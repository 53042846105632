import {CustomerObject} from "../models/CustomerObject";
import * as ActionTypes from "./actionTypes";

const initialState = {
  show: false,
  customer: CustomerObject,
};

function customerModalReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SHOW_CUSTOMER_MODAL:
      return {show: true, customer: action.payload};
    case ActionTypes.CLOSE_CUSTOMER_MODAL:
      return {show: false, customer: {}};
    default:
      return state;
  }
}

export default customerModalReducer;
