import {Button, Link, TextField} from "@material-ui/core";
import {XGrid} from "@material-ui/x-grid";
import React, {useState} from "react";
import {useQueryClient} from "react-query";
import {useSelector} from "react-redux";
import {SHOW_CUSTOMER_MODAL} from "./context/actions";
import store from "./context/store";
import EditForm from "./EditForm";
import useExportOrders from "./hooks/useExportOrders";
import useOrders from "./hooks/useOrders";
import StatusPopper from "./StatusPopper";
import "./Table.css";
import ToolTip from "./ToolTip";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function strcmp(a, b) {
  return a.localeCompare(b);
}

const formatDate = (date) =>
  new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  }).format(date);

export default function Table({tableID}) {
  const classes = useStyles();
  const [sortOrder, setSortOrder] = useState({orderId: "desc"});
  const [q, setQ] = useState("");
  const [showBackdrop, setShowBackdrop] = useState(false);
  const sortState = useSelector((state) => state.sortSelections);
  const authState = useSelector((state) => state.auth);
  const ordersQuery = useOrders(tableID, sortState[sortState.active], authState.customer);
  const queryClient = useQueryClient();
  const exportQuery = useExportOrders();

  const columns = (orderBy) => {
    const columns = [
      {
        field: "orderId",
        type: "number",
        headerName: "№",
        flex: 0.8,
        renderCell: (params) => (
          <EditForm user={authState.loggedUser} roles={authState.roles} order={params.row} />
        ),
      },
      {
        field: "billNo",
        type: "number",
        headerName: "Рах",
        flex: 0.6,
      },
      {
        field: "customer",
        headerName: "Замовник",
        flex: 2.2,
        sortComparator: (c1, c2) => strcmp(c1.customerName, c2.customerName),
        valueFormatter: (param) => param.value.customerName,
        renderCell: (params) =>
          authState.customer ? (
            <b>{params.value.customerName}</b>
          ) : (
            <Link
              className="name-link"
              onClick={() => store.dispatch(SHOW_CUSTOMER_MODAL(params.row.customer))}
            >
              {params.value.info ? (
                <ToolTip title={params.value.info}>
                  <b>{params.value.customerName}</b>
                </ToolTip>
              ) : (
                <b>{params.value.customerName}</b>
              )}
            </Link>
          ),
      },
      {
        field: "date",
        type: "dateTime",
        headerName: "Дата",
        flex: 1.2,
        valueFormatter: (param) => formatDate(param.value),
      },
      {
        field: "width",
        type: "number",
        headerName: "Ширина",
        valueFormatter: (row) => row.value,
        flex: 1.09,
      },
      {
        field: "height",
        type: "number",
        headerName: "Висота",
        valueFormatter: (row) => row.value,
        flex: 1,
      },
      {
        field: "amount",
        type: "number",
        headerName: "К-сть",
        valueFormatter: (row) => row.value,
        flex: 0.85,
      },
      {
        field: "m2",
        type: "number",
        headerName: "М2",
        valueFormatter: (row) => Number.parseFloat(row.value).toFixed(3),
        flex: 0.9,
      },
      {
        field: "price",
        type: "number",
        headerName: "Ціна",
        valueFormatter: (row) => row.value,
        flex: 0.8,
      },
      {
        field: "fees",
        type: "number",
        headerName: "Послуги",
        valueFormatter: (row) => row.value,
        flex: 1,
      },
      {
        field: "finalSum",
        type: "number",
        headerName: "Сума",
        flex: 0.9,
        renderCell: (params) => <b> {params.value} </b>,
      },
      {
        field: "paid",
        headerName: "Оплата",
        sortable: false,
        flex: 1.1,
        cellClassName: "centered",
        renderCell: (params) => {
          if (params.row.paySum === params.row.finalSum) {
            return (
              <ToolTip title={formatDate(new Date(params.row.payDate))} arrow>
                <img src="img/completed.svg" width="19" height="19" alt="paid" />
              </ToolTip>
            );
          } else if (params.row.paySum > 0)
            return (
              <ToolTip
                title={params.row.paySum + " грн, " + formatDate(new Date(params.row.payDate))}
                arrow
              >
                <img src="img/partly_paid.svg" width="19" height="19" alt="partly__paid" />
              </ToolTip>
            );
        },
      },
      {
        field: "status",
        headerName: "Статус",
        flex: 1.1,
        cellClassName: "centered",
        sortComparator: (c1, c2) => c1.id - c2.id,
        renderCell: (params) =>
          authState.customer ? (
            <ToolTip title={params.row.status.name} arrow>
              <StatusPopper order={params.row} disabled={true} />
            </ToolTip>
          ) : (
            <StatusPopper order={params.row} />
          ),
      },
      {
        field: "orderType",
        type: "string",
        headerName: "Вид",
        flex: 1.8,
        sortComparator: (c1, c2) => strcmp(c1.typeName, c2.typeName),
        valueFormatter: (param) => param.value.typeName,
      },
      {
        field: "orderKind",
        headerName: "Тип",
        flex: 1.8,
        sortComparator: (c1, c2) => strcmp(c1.kindName, c2.kindName),
        valueFormatter: (param) => param.value.kindName,
      },
      {
        field: "comment",
        headerName: "Опис замовлення",
        flex: 6,
        sortComparator: (c1, c2) => strcmp(c1, c2),
        renderCell: (params) => (
          <ToolTip title={params.value} placement="left-start">
            {params.value}
          </ToolTip>
        ),
      },
    ];

    Object.keys(orderBy).forEach(
      (key) => (columns.find((column) => column.field === key).sortDirection = orderBy[key])
    );
    return Object.values(columns);
  };

  const filter = (rows) => {
    if (!rows) return [];
    return rows.filter(
      (row) =>
        row.orderId?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.billNo?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.customer.customerName.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        formatDate(row.date)?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.fees?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.finalSum?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.amount?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.price?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.height?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.width?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.m2?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.orderKind.kindName?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.orderType.typeName?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1 ||
        row.comment?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
    );
  };

  const calculateDebt = (customer) => {
    let customerInfo;
    if (authState.roles.includes("ROLE_CUSTOMER")) {
      customerInfo = authState.customer;
    } else {
      const data = queryClient.getQueryData("customers");
      customerInfo = data.find((c) => c.customerId === customer.customerId);
    }

    let debt = 0;
    if (tableID === 2) {
      debt = ordersQuery.data?.listOfOrders?.reduce((a, b) => (a += b.finalSum - b.paySum), 0);
    } else {
      debt = ordersQuery.data.reduce((a, b) => (a += b.finalSum - b.paySum), 0);
    }
    return debt - customerInfo.money;
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={showBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <TextField
        variant="outlined"
        size="small"
        placeholder="Швидкий пошук"
        onChange={(e) => setQ(e.target.value)}
        InputProps={{
          endAdornment: authState.roles.includes("ROLE_ORDERS_EXPORTER") && (
            <Button
              className="export-btn"
              variant="outlined"
              onClick={() =>
                exportQuery.mutate({
                  orders: filter(
                    tableID !== 2 ? ordersQuery.data : ordersQuery.data.listOfOrders
                  ),
                  setBackdrop: setShowBackdrop,
                })
              }
            >
              Експорт
            </Button>
          ),
        }}
      />

      <div className="x-grid">
        {(sortState[sortState.active].customer || authState.customer) && (
          <div className="action__bar__stat">
            <label>
              Сума боргу : <b>{calculateDebt(sortState[sortState.active].customer)}</b> грн
            </label>
          </div>
        )}
        {tableID === 2 && (
          <div className="action__bar__stat sort__info">
            <label>
              Кількість : <b>{ordersQuery.data.amount}</b>
            </label>
            <label>
              M2 : <b>{ordersQuery.data.m2}</b>
            </label>
            <label>
              Послуги : <b>{ordersQuery.data.fees}</b>
            </label>
            <label>
              Сума : <b>{ordersQuery.data.sum}</b>
            </label>
            <label>
              Оплачено : <b>{ordersQuery.data.paid}</b>
            </label>
          </div>
        )}
        <div className="frame">
          <div className="grid-holder">
            <XGrid
              rows={filter(tableID !== 2 ? ordersQuery.data : ordersQuery.data.listOfOrders)}
              columns={columns(sortOrder)}
              onSortModelChange={(e) => {
                let sortModel = {};
                if (e.sortModel.length > 0) {
                  sortModel[e.sortModel[0]?.field] = e.sortModel[0]?.sort;
                } else {
                  sortModel["orderId"] = "desc";
                }
                setSortOrder(sortModel);
              }}
              loading={ordersQuery.isLoading}
              rowHeight={35}
              headerHeight={35}
              pagination={true}
              disableSelectionOnClick={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
