import {FormControlLabel, Switch} from "@material-ui/core";
import {useField} from "formik";
import React from "react";

const SwitchControl = ({label, isChecked, ...props}) => {
  const [field] = useField(props);
  return (
    <FormControlLabel
      control={<Switch {...props} {...field} checked={isChecked} color="primary" />}
      label={label}
    />
  );
};

export default SwitchControl;
