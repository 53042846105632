import Axios from "axios";
import {useMutation, useQueryClient} from "react-query";

export default function useSaveDimensions() {
  const queryClient = useQueryClient();
  return useMutation((params) => Axios.put("/api/order-kinds/", params.orderKind), {
    onSuccess: (_, passedParams) => {
      queryClient.invalidateQueries("orderTypes");
      passedParams.setStatus({success: true, error: false});
    },
    onError: (_, passedParams) => {
      passedParams.setStatus({success: false, error: true});
    },
  });
}
