import Axios from "axios";
import {useMutation, useQueryClient} from "react-query";

export default function useMakePayment() {
  const queryClient = useQueryClient();
  return useMutation((params) => Axios.post("/api/payments/", params.payment), {
    onError: (_, params) => {
      params.setStatus({success: false, error: true});
    },
    onSuccess: (_, params) => {
      params.setStatus({success: true, error: false});
      queryClient.invalidateQueries(["customers"]);
      queryClient.invalidateQueries(["orders"]);
      params.payment.sum = 0;
    },
  });
}
